import './DrinkCalculator.scss';
import './SendShoppingList.scss';
import React, {} from 'react';
import SendShoppingListContent from './SendShoppingListContent';

function SendShoppingList(props) {

  return (
    <div className="card-content send-shopping-list-modal">
      <SendShoppingListContent {...props} />
    </div>
  )
};

export default SendShoppingList;