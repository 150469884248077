import './SignUp.scss';
import React, {useState} from "react";
import LoadingButton from "Components/LoadingButton";
import InputBlock from "Components/InputBlock.js";
import Checkbox from "Components/Checkbox";
import Render from "Components/Render";
import { ENDPOINTS } from "Utilities/Constants";
import { useApiWorker, useSubmitHandler } from "Utilities/CustomHooks";
import { useHistory } from 'react-router-dom';
import {setToken} from 'Utilities/AuthService';
import SignUpSuccess from './SignupSuccess';
import { persistSocialEvent } from 'Utilities/LocalSocialEventService';

function SignUp(props){
    const {config, setIsModalShowing, collapse, redirectTo, noRedirect,  onBeforeLogin, onHandleSuccess} = props;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [handleSubmit, {errorState}] = useSubmitHandler(ENDPOINTS.signup.main, config.baseUrl)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [] = useApiWorker(config.baseUrl);
    const [, setErrorMessage] = useState("");
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const {setLogginChanging} = config;
    const [newsLetterSubscription, setNewsLetterSubscription] = useState(false);

    let history = useHistory();

    const handleSuccess = (res) => {
        setLogginChanging(true);
        const {token} = res.data || {};
        setIsSubmitting(false); 

        if (token) {
            setToken(token);
            setRegistrationComplete(true);
            persistSocialEvent(config.baseUrl);
        } else {
            setErrorMessage(res.errorMessage);
        }
        if(redirectTo && !noRedirect){
            history.push(redirectTo);
        }
        if (collapse) {
            collapse();
        }
        if (onHandleSuccess) onHandleSuccess(res);
      }

    const signUp = async () => {
        setIsSubmitting(true);
        var data = {
            firstName: firstName, lastName: lastName, userName: userName,
            email: email, password: password, confirmPassword: confirmPassword,
            phoneNumber: phoneNumber,  zipCode: zipCode, appendCookie: true, newsletterSubs: newsLetterSubscription
        }
       
        if (onBeforeLogin) onBeforeLogin(data);
        
        await handleSubmit(data, (res) => {handleSuccess(res)}, ({data}) => {setIsSubmitting(false); setErrorMessage(data.ErrorMessage)});
        
        window.scrollTo(0, 0);
    }

    return(
        <div className="card-content sign-up-modal">
            <Render condition={!registrationComplete}>
                <div className="sign-title centered">
                    Sign Up!
                </div>
                <div id="signup-form" className="main-description">
                    <div className="flex-column full-width">
                        <InputBlock value={firstName} setValue={setFirstName} name="first name" id="firstname-input" placeholder="first name"
                        errorMessage={errorState["FirstName"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={lastName} setValue={setLastName} name="last name" autoComplete="last name" id="lastname-input" placeholder="last name"
                        errorMessage={errorState["LastName"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={userName} setValue={setUserName} name="username" autoComplete="username" id="username-input" placeholder="username"
                        errorMessage={errorState["UserName"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={email} setValue={setEmail} name="email" autoComplete="email" id="email-input" placeholder="email@email.com"
                        errorMessage={errorState["Email"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={password} type="password" setValue={setPassword} name="password" id="password-input" placeholder="password"
                        errorMessage={errorState["Password"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={confirmPassword} type="password" setValue={setConfirmPassword} name="confirm password" id="confirm-password-input" placeholder="confirm password"
                        errorMessage={errorState["ConfirmPassword"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={phoneNumber} setValue={setPhoneNumber} name="phone number" id="phone-input" placeholder="phone number"
                        onValueChange={(values) => setPhoneNumber(values.value)}
                        format="(###) ###-####" isNumberFormat={true} useUnformattedValue={true}
                        errorMessage={errorState["PhoneNumber"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <InputBlock value={zipCode} setValue={setZipCode} name="zip code" id="zip-input" placeholder="zip code"
                        errorMessage={errorState["ZipCode"]}/>
                    </div>
                    <div className="flex-column full-width">
                        <Checkbox value={newsLetterSubscription} setValue={setNewsLetterSubscription}>
                            <label className="news-letter-subs-message">
                                Put me on the list! Click here & we'll keep you in the loop on what we're doing at The Drink Calculator. you definitely won't get spammed, but you just might get a jello shot.
                            </label>
                        </Checkbox>
                    </div>
                    <div className="button-row centered">
                        <LoadingButton loading={isSubmitting} onClick={signUp}
                            className="btn-primary" color="primary">
                            Sign Up
                        </LoadingButton>
                    </div>
                 </div>
            </Render>
            <Render condition={registrationComplete}>
                <SignUpSuccess collapse={collapse}/>
            </Render>

        </div>
    )

}

export default SignUp;