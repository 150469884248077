import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ROUTES } from 'Utilities/Constants';
import Login from '../User/Login';
import ModalCloseButton from 'Components/ModalCloseButton';
import './PasswordChangedSuccess.scss';

function PasswordChangedSuccess(props){
	const {config} = props;
	const [modal, setModal] = useState(false);
	const toggle = ()=> setModal(!modal);

	return(
		<div className="password-changed-success">
			<h2>Raise a glass!</h2>
			<h4>Your password has been changed.</h4>
			<div className="centered">
				<Button className="login-btn btn-primary" color="primary" onClick={toggle}>Log in</Button>
			</div>
			<Modal isOpen={modal} toggle={toggle} className="login-modal" backdrop={true} backdropClassName="login-modal-backdrop">
				<ModalBody>
					<ModalCloseButton toggle={toggle}></ModalCloseButton>
					<Login config={config} toggle={toggle} redirectTo={ROUTES.myAccount} noRedirect={false}></Login>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default PasswordChangedSuccess;