import "./DrinkCalculator.scss";
import React from 'react';
import { Row, Col } from 'reactstrap';

function EssentialItemRow(props) {
  const {essentialItem, isLastEntry, ...others} = props;

  return (
    <div className={"recommendation-row essential-row " + (isLastEntry ? "last-row" : "")}>
      <Row>
        <Col xl={4} lg={4} med={4} sm={4} xs={12} className="drink-select-column">
          <div className="column-wrapper centered">
            <div className="sponsor-image-wrapper">
              <img className="sponsor-image" src={essentialItem.logoFileId} alt="" />
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} med={4} sm={4} xs={12}>
          <div className="column-wrapper centered">
            <div className="recommendation-amount">
              {essentialItem.amount} 
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} med={4} sm={4} xs={12}>
          <div className="column-wrapper centered flex-column recommendation-name">
            <div className="style-name full-width">{essentialItem.name}</div>
            <div className="sponsor-product-name full-width">{essentialItem.description}</div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EssentialItemRow;