import React, {useEffect} from 'react';
import {EXTERNAL_ENDPOINTS, MAP_ID, StoreLocatorSelectors} from 'Utilities/Constants';
import {attachEventListener} from 'Utilities/HelperFunctions';

function MapContainer(){

    useEffect(() =>{
        const handleChange = (element) => {
            document.querySelector(StoreLocatorSelectors.resultPanel).style.visibility = 'visible';
        }

        const eventListener = attachEventListener(StoreLocatorSelectors.zipcodeInput, 'change', handleChange);
        
        return function cleanupListener() {
            document.removeEventListener("change", eventListener)
        }
    }, [])

    return(
        <div className="map-wrapper">
            <div id="storepoint-container" data-map-id={MAP_ID}></div>
            <script>{
                (function(){
                    var a=document.createElement("script"); a.type="text/javascript"; a.async=!0;
                    a.src= EXTERNAL_ENDPOINTS.STOREPOINT;               
                    var b=document.getElementsByTagName("script")[0]; b.parentNode.insertBefore(a,b);
                }())
            }</script>
        </div>       
    )
}

export default MapContainer;