import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ROUTES } from 'Utilities/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';
import LoadingSection from 'Components/LoadingSection';
import CravenLine from '../../Assets/cravenlinestyle.svg';
import Slider from 'Components/Slider';
import LoadingButton from 'Components/LoadingButton';

function ShowSpecs(props){
	const {socialEventId, eventName, eventDate, numberOfGuests, 
		setNumberOfGuests, thirstLevel, numberOfHours, isSocialEventInfoLoading, onClickSave} = props;

	let history = useHistory();

	const [saveButtonLoading, setSaveButtonLoading] = useState(false);

	const goBack = ()=>{
		history.push(ROUTES.liquorCabinet);
	}

	const onClick = ()=>{
		onClickSave({setSaveButtonLoading});
	}

	return(
		<LoadingSection isLoading={isSocialEventInfoLoading}>
			<Row className="specs-section">
				<Col lg={12}>
					<h4 className="d-none d-lg-block event-date">{eventDate}</h4>
				</Col>
				<Col lg={4} md={2} className="d-none d-md-block craven-line-container">
					<img src={CravenLine} className="craven-line"/>
				</Col>
				<Col lg={8} md={10}>
					<Row>
						<Col lg={12} md={6} className="the-spects">
							<h4 className="d-block d-lg-none lc-date" >{eventDate}</h4>
							<div className="the-spects-title">THE SPECS</div>
							<div className="the-spects-total">
								<Row className="specs-detail">
									<Col md={1}  className="centered"><FontAwesomeIcon icon={Icon.faUserCircle}/></Col>
									<Col md={11} className="spect">GUEST:<span className="ml-1">{numberOfGuests}</span></Col>
									<Col md={1}  className="centered"><FontAwesomeIcon icon={Icon.faClock}/></Col>
									<Col md={11} className="spect">HOURS:<span className="ml-1">{numberOfHours}</span></Col>
									<Col md={1}  className="centered"><FontAwesomeIcon icon={Icon.faTint}/></Col>
									<Col md={11} className="spect">THIRST LEVEL:</Col>
									<Col md={12} className="centered">
										<Slider leftLabel="a little" rightLabel="a lot" value={thirstLevel} disabled={true} />
									</Col>
								</Row>								
							</div>
							<button className="btn btn-secondary btn-block mt-4" onClick={goBack}>
								BACK
							</button>
							<button className="btn btn-lightgreen btn-primary" onClick={onClick}>
								SAVE
							</button>
						</Col>
						<Col lg={4} md={2} className="d-block d-sm-none craven-line-vertical">
							<img src={CravenLine} className=""/>
						</Col>
						<Col lg={12} md={6} className="paragraphs pull-left">
							<p>Date not ringing a bell? Give your event a memorable name.</p>
							<p>Buy enough ice to build your own igloo? Make a note to help you dial details for next time.</p>
							<p>Recommended brands a homerun with your your crowd? Capture your favorites by rating each.</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</LoadingSection>
	);
}
export default ShowSpecs;
