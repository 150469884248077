import React, {useState} from 'react';
import LoadingButton from 'Components/LoadingButton';
import InputBlock from 'Components/InputBlock.js';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from 'Utilities/Constants';
import { useSubmitHandler } from 'Utilities/CustomHooks';
import ErrorDisplay from 'Components/ErrorDisplay';
import { setToken } from 'Utilities/AuthService';
import { ROUTES } from 'Utilities/Constants';
import { persistSocialEvent } from 'Utilities/LocalSocialEventService';
import './Login.scss';

function LoginForm(props){
    const {config, redirectTo, noRedirect, collapse,  onBeforeLogin, onHandleSuccess, onHandleError,...other} = props;
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [handleSubmit, {response, errorState}] = useSubmitHandler(ENDPOINTS.login.main, config.baseUrl);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {logginChanging, setLogginChanging} = config;

    let history = useHistory();
    const handleSuccess = (res) => {
        setLogginChanging(true);
        const {token} = res.data || {};
        if(token){
            setToken(token);
        }
        if(collapse){
            collapse();
        }
        
        if(redirectTo && !noRedirect){
            history.push(redirectTo);
        }
        else if(!noRedirect){
            history.push(ROUTES.signupSuccess);
        }
        persistSocialEvent(config.baseUrl);
        if (onHandleSuccess) onHandleSuccess(res);
    }

    const login = async () => {
        setIsSubmitting(true);
        var data = {
            userName: emailAddress, password: password, appendCookie: true
        }

        if ( onBeforeLogin)  onBeforeLogin(data);

        await handleSubmit(data, (res) => {
            handleSuccess(res)
        }, ({data}) => {
            setIsSubmitting(false); setErrorMessage(data.ErrorMessage);
            if(onHandleError) onHandleError(data.ErrorMessage);
        });

        window.scrollTo(0, 0);
    }

    const onKeyPress = (e)=>{
        if(e.keyCode == 13){
            login();
        }
    }
    
    return(
        <div id="login-form">
            <ErrorDisplay error={errorState.BadRequest}/>
            <div className="flex-column full-width login-input">
                <InputBlock value={emailAddress} setValue={setEmailAddress} name="email" autoComplete="email" id="email-input" placeholder="email or username"
                errorMessage={errorState["Username"]}/>
            </div>
            <div className="flex-column full-width login-input">
                <InputBlock value={password} type="password" setValue={setPassword} name="password" autoComplete="password" id="password-input" placeholder="password"
                errorMessage={errorState["Password"]} onKeyDown={onKeyPress}/>
            </div>
            <div className="button-row centered login-input">
                <LoadingButton loading={isSubmitting} onClick={login}
                className="btn-primary btn-lightgreen" color="primary">
                    Log in
                </LoadingButton>
            </div>
        </div>
    )
}

export default LoginForm;
