import './StoreLocator.scss';
import React from 'react';
import StoreLocatorWelcomePage from './StoreLocatorWelcome';
import StoreLocatorModalWrapper from './StoreLocatorModalWrapper';
import MapContainer from 'Components/MapContainer';

function StoreLocator (props) {
    return(
        <div>
            <StoreLocatorWelcomePage {...props}/>
            {/* this modal wrapper is used since every opened the modal at this component level it re-rendered the MapContainer script */}
            <StoreLocatorModalWrapper config={props.config} />
            <MapContainer />
        </div>     
    )
}

export default StoreLocator;