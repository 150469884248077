import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';

function LoginButton(props){
    const {handleClick, ...others} = props;
    
    return(
    <nav className="navbar navbar-light bg-faded">
        <button aria-label="Login" type="button" className="mr-2 navbar-toggler" onClick={handleClick}>
            <FontAwesomeIcon className="login-icon" icon={Icon.faUser}/>
        </button>
    </nav>
    )
}

export default LoginButton;