import React from 'react';
import {Button, Row, Col} from 'reactstrap';
import Render from 'Components/Render';
import './Checkbox.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';

function Checkbox({value, setValue, children}){
	const onClick = ()=>{
		setValue(value ? false : true);
	}
	return(
		<div className="custom-checkbox">
			<Row>
				<Col xs={1}>
					<Button className="input-checkbox btn-secondary" onClick={onClick}>
						<Render condition={value}>
							<FontAwesomeIcon  icon={Icon.faCheck}/>
						</Render>
					</Button>	
				</Col>
				<Col xs={11} className="label-checkbox">
					{children}
				</Col>
			</Row>
		</div>
	);
}

export default Checkbox;