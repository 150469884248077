import React from 'react';
import { Row, Col } from 'reactstrap';
import Render from 'Components/Render';
import Slider from 'Components/Slider';
import CheckableBox from 'Components/CheckableBox';

function DrinkTypeSelectBox(props){
const {id, unclickable, handleClick, handleSliderClick, setPriceValue, setVolumeValue, ...type} = props;


return(
    <Col xl={4} lg={4} med={4} sm={6} xs={12} key={id} className="drink-select-column">
    <div className="column-wrapper centered">
      <CheckableBox outlined={true} selected={type.isSelected} short={!type.isAlcoholic} handleClick={handleClick} unclickable={unclickable}>
        <div className="drink-type-name full-width">
          {type.name}
        </div>
        <Render condition={!type.isMixers && type.isAlcoholic}>
          <div className="full-width">
            <Slider value={type.pricePoint} setValue={setPriceValue} leftLabel="$" middleLabel="$$" rightLabel="$$$" disabled={!type.isSelected || unclickable}
              handleSelect={handleSliderClick}
            />
          </div>
          <div className="full-width">
            <Slider value={type.drinkVolume} setValue={setVolumeValue} leftLabel="less" rightLabel="more" disabled={!type.isSelected || unclickable}
              handleSelect={handleSliderClick}
            />
          </div>
        </Render>
      </CheckableBox>
    </div>
  </Col>
);
    
}

export default DrinkTypeSelectBox;