import "./FormInput.scss";
import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ErrorDisplay from "./ErrorDisplay";

function FormInput ({value, setValue, label, errorMessage, isNumberFormat=false, useUnformattedValue=false, colSize, ...other}) { 
    const handleChange = (e) => {
      if(!useUnformattedValue) {
        setValue && setValue(e.target.value);
      }
    }


    return (
        <div className={"form-input-wrapper " + colSize}>
            {
                isNumberFormat 
                ? <NumberFormat customInput={Input} defaultValue={value} onChange={handleChange} {...other} />
                : <Input defaultValue={value} onChange={handleChange} {...other} />
            }
        <ErrorDisplay error={errorMessage}/>
        </div>
    );
};

FormInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string)
}

export default FormInput;