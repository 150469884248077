import React, {useState, useEffect} from 'react'
import LoadingButton from 'Components/LoadingButton';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSubmitHandler } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import InputBlock from 'Components/InputBlock.js';
import './ChangePassword.scss';

function ChangePassword (props){
    const {config, emailAddress, handleSuccess, handleError, modalShow, modalToggle, isForgettingPassword} = props;
    const [changePasswordLoading , setChangePasswordLoading] = useState(false);
    const [email, setEmail] = useState(emailAddress);
    const [handleSubmit, {errorState}] = useSubmitHandler(ENDPOINTS.user.requestRecoveryPassword, config.baseUrl)
    const [, setErrorMessage] = useState("");

    const handleResponse = () => {
        handleSuccess(true);		
    }

	const changePassword = async ()=>{
		setChangePasswordLoading(true);
		try{
            var resp = await handleSubmit({emailAddress: email, isForgettingPassword: isForgettingPassword }, (res) => {handleResponse(res)}, ({data}) => {setChangePasswordLoading(false); setErrorMessage(data.ErrorMessage);});   
        }
		catch (e){
			handleError("Something was wrong!");
		}	
		setChangePasswordLoading(false);
        modalToggle();
    }

    useEffect(() => {
        setEmail(emailAddress);
    }, [emailAddress])

    return(
        <Modal isOpen={modalShow} toggle={modalToggle} className="change-password-modal">
            <ModalHeader toggle={modalToggle}>Reset Password</ModalHeader>
            <ModalBody>     
                {emailAddress === "" &&
                    <div>
                    Please enter your email address below. You will receive a link to reset your password.
                    <InputBlock value={email} setValue={setEmail} name="emailAddress" autoComplete="emailAddress" id="email-address-input" placeholder="email"
                    errorMessage={errorState["EmailAddress"]} />
                    </div>
                }
                {emailAddress !== "" &&
                    <div>Are you sure to change your password?</div>
                }
                <div className="action-buttons">
                    <LoadingButton color="primary" loading={changePasswordLoading} onClick={changePassword}>Submit</LoadingButton>
                    <Button color="secondary" onClick={modalToggle}>Cancel</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ChangePassword;