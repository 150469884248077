import "./InputBlock.scss";
import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ErrorDisplay from "./ErrorDisplay";

function TextareaBlock({value, setValue, label, errorMessage, isNumberFormat=false, useUnformattedValue=false, ...other}) { 
  const handleChange = (e) => {
    if(!useUnformattedValue) {
      setValue && setValue(e.target.value);
    }
  }

  return (
    <div>
      <div className="input-label">{label}</div>
      <textarea defaultValue={value} onChange={handleChange} {...other} className="full-width"/>
      <ErrorDisplay error={errorMessage}/>
    </div>
  );
};

TextareaBlock.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string)
}

export default TextareaBlock;