import React from 'react';
import "./ModalCloseButton.scss";
import PropTypes from 'prop-types';

function ModalCloseButton({toggle, ...others}) {
  return (
    <button onClick={toggle} className="close-button" aria-label="Close">
      <span aria-hidden={true}>×</span>
    </button>
  )
}

ModalCloseButton.propTypes = {
  toggle: PropTypes.func
}

export default ModalCloseButton;

