import React, {useState} from 'react';
import LoadingSection from 'Components/LoadingSection';
import InputBlock from 'Components/InputBlock.js';

function SocialEventNameNotes(props){
	const {eventName, setEventName, eventNotes, setEventNotes, isSocialEventInfoLoading} = props;

	return(
		<LoadingSection isLoading={isSocialEventInfoLoading}>
			<div className="pull-left full-width event-details-form" >
				<InputBlock value={eventName} labelTop="Name your event" setValue={setEventName} name="eventName" id="event-name-input" placeholder="Pool Party"/>
				<InputBlock type="textarea" value={eventNotes} labelTop="Notes"  setValue={setEventNotes} name="eventNotes" id="event-notes-input" 
					placeholder="I chose Thirst Level: A Lot, but this crowd was only a little thirsty. Next time, omit beer altogether" />
			</div>
		</LoadingSection>
	);
}

export default SocialEventNameNotes;