import './DrinkCalculator.scss';
import React, {useState, useContext, useEffect, Fragment} from 'react';
import {useSubmitHandler, useDrinkSyleRouter, useCalculatorContext} from 'Utilities/CustomHooks';
import Slider from 'Components/Slider';
import LoadingButton from 'Components/LoadingButton';
import ActionTypes from 'Context/ActionTypes';
import { Row, Col } from 'reactstrap';
import CheckableBox from 'Components/CheckableBox';
import { useHistory } from 'react-router-dom';
import { ROUTES, ENDPOINTS } from 'Utilities/Constants';
import {objToList, listToObj, getClosest, mapObjectEntries, compareOrderables} from 'Utilities/HelperFunctions';
import RefreshRedirect from './RefreshRedirect';
import ErrorDisplay from 'Components/ErrorDisplay';
import LoadingSection from 'Components/LoadingSection';
import Render from 'Components/Render';
import ReactGA from 'react-ga';
import { ModalBody } from 'reactstrap';
import UserGuide from './StockBarUserGuide';
import ModalCloseButton from 'Components/ModalCloseButton';
import DrinkTypeSelectBox from 'Components/DrinkTypeSelectBox';


function DrinkTypeSelection(props) {
  const {config, ...other} = props;

  let history = useHistory();
  const [state, sendUpdate] = useCalculatorContext();
  const [handleSubmit, {response, errorState, apiWorker}] = useSubmitHandler(ENDPOINTS.drinkTypes.validate, config.baseUrl);
  const [getPrevAndNextUrl] = useDrinkSyleRouter();

  const [drinkTypes, setDrinkTypes] = useState(listToObj(state.drinkTypeSelections || []));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const setDrinkVolume = (key, volume) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], drinkVolume: volume}}));
  }

  const setPricePoint = (key, price) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], pricePoint: price}}));
  }

  const setIsSelected = (key) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], isSelected: !prevTypes[key].isSelected}}));
  }

  const handleBoxClick = (e, id) => {
    setIsSelected(id);
  }

  const saveAndRedirect = async (shouldGoBack) => {
    const drinkList = objToList(drinkTypes);
    await sendUpdate(ActionTypes.setDrinkTypes, drinkList.sort(compareOrderables));
    if(shouldGoBack) {
      history.push(ROUTES.eventSetup);
    } else {
      var {nextUrl, prevUrl} = getPrevAndNextUrl(drinkList || [], null);
      history.push(nextUrl);
    }
  }

  const addDrinkTypes = () => {
    const drinkList = objToList(drinkTypes).filter(x => x.isSelected);

    setIsSubmitting(true);
    
    handleSubmit({DrinkTypes: drinkList}, 
      async (res) => await saveAndRedirect(false), 
      () => {window.scrollTo(0, 0)}, () => setIsSubmitting(false)  
    );
  }

  const prevPage = async () => {
    await saveAndRedirect(true);
  }

  const handleSliderClick = (e, val) => {
    if(getClosest(e.target, '.slider-outer-wrapper.disabled') === null) {
      e.stopPropagation();
    }
  }

  const shouldShowType = (type, id) => {
    if(type.isMixers) {
      var isLiquorSelected = Object.entries(drinkTypes).some(([id, type]) => type.isSelected && type.isLiquor);
      if(!isLiquorSelected && type.isSelected) {
        setIsSelected(id);
      }

      return isLiquorSelected;
    }

    return true;
  }
  
  const [isModalShowing, setIsModalShowing] = useState(false);

  const toggleModal = () => {
    if(!isModalShowing) {
      ReactGA.modalview('stock-bar-user-guide-modal');
    }
    setIsModalShowing(!isModalShowing);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const getDrinkTypes = async () => {
      const endpoint = state.event.isAlcoholicEvent 
        ? ENDPOINTS.drinkTypes.getAlcoholicDrinks 
        : ENDPOINTS.drinkTypes.getNonAlcoholicDrinks;
      const res = await apiWorker.get(endpoint);
      const objTypes = listToObj(res.data);
      for(var key of Object.keys(objTypes)) {
        objTypes[key].pricePoint = 2;
        objTypes[key].drinkVolume = 2;
        objTypes[key].isSelected = false;
      }
      setDrinkTypes(objTypes);
      setIsPageLoading(false);
    }
    setIsPageLoading(true);
    if(state.drinkTypeSelections === undefined || state.drinkTypeSelections.length == 0) {
      getDrinkTypes();
    } else {
      setIsPageLoading(false);
    }
  }, [state.drinkTypeSelections]);

  return (
    <RefreshRedirect>
       <Render condition={(!isModalShowing)}>
      <div className="card-content">
        <h1>stock your bar</h1>
        <div className="drink-selection-help">
          Select each type of alcohol you'll be serving at your party, then slide the price point + proportion sliders.
          <div className="centered">
            <LoadingButton loading={false} disabled={false} onClick={toggleModal}
                className="btn-secondary" color="secondary">
                more help 
            </LoadingButton>
          </div>
        </div>
        <LoadingSection isLoading={isPageLoading}>
          <ErrorDisplay error={errorState.DrinkTypes} className="drink-types-error"/>
          <Row>
            {
              mapObjectEntries(drinkTypes, compareOrderables, ([id, type]) => 
                <Render condition={shouldShowType(type, id)} key={id}>
                  <DrinkTypeSelectBox id={id} unclickable={false} handleClick={(e) => {handleBoxClick(e, id)}} handleSliderClick={handleSliderClick}
                    setPriceValue={(val) => {setPricePoint(id, val)}} setVolumeValue={(val) => {setDrinkVolume(id, val)}} {...type} />              
                </Render>
              )
            }
          </Row>
          <div className="centered button-row">
            <LoadingButton disabled={isSubmitting} 
              className="btn-secondary" color="secondary" onClick={prevPage}>
                Go Back
            </LoadingButton>
            <LoadingButton loading={isSubmitting} disabled={isSubmitting} 
              className="btn-primary" color="primary" onClick={addDrinkTypes}>
                Next Step
            </LoadingButton>
          </div>
          
        </LoadingSection>
      </div>
      </Render>
        <Render condition={isModalShowing}>
          <ModalBody>
            <ModalCloseButton toggle={toggleModal} />
            <UserGuide config = {config} setIsModalShowing={setIsModalShowing}/>
          </ModalBody>
        </Render>
    </RefreshRedirect>
  )
};

export default DrinkTypeSelection;