import './StoreLocator.scss'
import React, {useState} from 'react';
import { Modal, ModalBody} from 'reactstrap';
import Render from 'Components/Render';
import ModalCloseButton from 'Components/ModalCloseButton';
import {loggedIn} from 'Utilities/AuthService';
import SignUp from '../User/SignUp';
import Login from '../User/Login';

function StoreLocatorWelcome(props) {
    const {config, ...others} = props;
    const [isSignUpShowing, setSignUpShowing] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const toggleLoginModal = () => setLoginModalOpen(!loginModalOpen);
    const toggleSignUpModal = () => setSignUpShowing(!isSignUpShowing);
    
    return (
        <div className="welcome-store-locator">
        <div className={"welcome-content"}>
          <h3 className="store-locator-title full-width centered">The Store Locator</h3>
            <div className="full-width justified">
              Welcome! We've gathered our favorite local wine, beer + liquor shops to make placing your bar order a breeze. 
              Enter your zip code, and choose if you'd like to see stores that offer online ordering + pickup, delivery or both. 
              Once you find your perfect store match, select Get a Quote + you'll be well on your way to pro-host status.
              
          </div>
          <Render condition={!loggedIn()}>
            <div className="login-area full-width">
            To streamline your ordering process, make sure you're <a onClick={toggleLoginModal}>logged in</a>, or <a onClick={toggleSignUpModal}>create an account.</a>
            </div>
          </Render>  
        </div>
        <Modal isOpen={loginModalOpen} toggle={toggleLoginModal} className="login-modal" backdrop={true} backdropClassName="primary-backdrop">
            <ModalBody>
                <ModalCloseButton toggle={toggleLoginModal} />        
                <Login config={config} toggle={toggleLoginModal} noRedirect={true}></Login>
            </ModalBody>
        </Modal>
        <Modal isOpen={isSignUpShowing} toggle={toggleSignUpModal} className="signup-modal" backdrop={true} backdropClassName="primary-backdrop">
            <ModalCloseButton toggle={toggleSignUpModal} />
            <ModalBody>
                <SignUp config={config} setIsModalShowing={toggleSignUpModal} noRedirect={true} collapse={toggleSignUpModal}></SignUp>
            </ModalBody>
        </Modal>
      </div>
    )
}

export default StoreLocatorWelcome;