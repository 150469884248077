import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component, componentProps, layout, ...others }) => {
  const Component = component;
  const LayoutComponent = layout;

  if (LayoutComponent !== undefined) {
    return (
      <Route {...others}>
        <LayoutComponent>
          <Component {...componentProps}/>
        </LayoutComponent>
      </Route>
    );
  }
  else {
    return (
      <Route {...others}>
          <Component {...componentProps}/>
      </Route>
    );
  }
};

export default LayoutRoute;