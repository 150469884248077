const ActionTypes = {
  setEvent: "SET_EVENT",
  getEvent: "GET_EVENT",
  addDrinkTypes: "ADD_DRINK_TYPES",
  setDrinkTypes: "SET_DRINK_TYPES",
  getDrinkTypes: "GET_DRINK_TYPES",
  addDrinkStyles: "ADD_DRINK_STYLES",
  setDrinkStyles: "SET_DRINK_STYLES",
  getDrinkStyles: "GET_DRINK_STYLES",
  setResults: "SET_RESULTS",
  getResults: "GET_RESULTS",
  setUserEvent: "SET_USER_EVENT",
  clearTypes: "CLEAR_TYPES",
  clearEvent: "CLEAR_EVENT",
  setDeliveryTypes: "SET_DELIVERY_TYPES",
  setStoreInformation: "SET_STORE_INFORMATION",
  clearEventPersistStoreInfo: "CLEAR_EVENT-PERSISTE_STORE-INFO"
}

export default ActionTypes;