import React, { useEffect, useState } from 'react';
import BackgroundImage from 'Features/Home/BackgroundImage';

let isFixedGlobal;

export default function DynamicBackground({image, children, ...others}) {
  const [isFixed, setIsFixed] = useState(false);

  const imgHeight = 1800;
  const headerHeight = 160;
  const totalHeight = imgHeight + headerHeight;

  isFixedGlobal = isFixed;

  const handleScroll = () => {
    var scrollTop = window.scrollY;
    var startFixedHeight = totalHeight - window.innerHeight;
    if (scrollTop > startFixedHeight && !isFixedGlobal) {
      setIsFixed(!isFixedGlobal);     
    }

    if (scrollTop <= startFixedHeight && isFixedGlobal) {
      setIsFixed(!isFixedGlobal);   
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup () {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="dynamic-background">
      <BackgroundImage image={image} className={isFixed ? "make-fixed " : "make-absolute "} />
      {children}
    </div>
  )
}