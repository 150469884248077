import React, {useState, useEffect} from 'react';
import NewTabLink from 'Components/NewTabLink';
import Render from 'Components/Render';
import {useApiWorker} from 'Utilities/CustomHooks';
import {ENDPOINTS} from 'Utilities/Constants';

function DiscourseNavItem(props){
	const {config} = props;
	const [apiWorker] = useApiWorker(config.baseUrl);
	const [url, setUrl] = useState(null);
	const [loaded, setLoaded] = useState(false);

	useEffect(()=>{
		const loadUrl = async ()=>{
			var resp = await apiWorker.get(ENDPOINTS.discourse.getUrl);
			setUrl(resp.data + "/session/sso");
			setLoaded(true);
		}
		loadUrl();
	}, []);

	return(
		<Render condition={loaded}>		
			<NewTabLink className="nav-item" url={url}>Party Animals</NewTabLink>		
		</Render>
	);
}

export default DiscourseNavItem;