import './DrinkCalculator.scss';
import React, {useState} from 'react';
import {useSubmitHandler, useCalculatorContext} from 'Utilities/CustomHooks';
import Incrementor from 'Components/Incrementor';
import Slider from 'Components/Slider';
import LoadingButton from 'Components/LoadingButton';
import ErrorDisplay from 'Components/ErrorDisplay';
import ActionTypes from 'Context/ActionTypes';
import { useHistory } from 'react-router-dom';
import {ROUTES, ENDPOINTS} from '../../Utilities/Constants';
import DiscourseButton from 'Components/DiscourseLoginButton';

function EventSetup(props) {
  const {config, ...others} = props;

  let history = useHistory();
  const [state, sendUpdate] = useCalculatorContext();

  const [numberOfGuests, setNumberOfGuests] = useState(state.event.numberOfGuests);
  const [numberOfHours, setNumberOfHours] = useState(state.event.numberOfHours);
  const [thirstLevel, setThirstLevel] = useState(state.event.thirstLevel);
  const [isSelectingAlcholic, setIsSelectingAlcoholic] = useState(false);
  const [isSelectingNonAlcoholic, setIsSelectingNonAlcoholic] = useState(false);

  const [handleSubmit, {response, errorState, setErrorState}] = useSubmitHandler(ENDPOINTS.socialEvents.validate, config.baseUrl);

  const handleSuccess = async (res, data) => {
    if(data.isAlcoholicEvent !== state.event.isAlcoholicEvent) {
      await sendUpdate(ActionTypes.clearTypes, {});
    }
    await sendUpdate(ActionTypes.setEvent, data); 
    history.push(ROUTES.drinkSelection);
  }

  const createEvent = (isAlcoholicEvent) => {
    const data = {
      name: "",
      numberOfGuests: numberOfGuests,
      numberOfHours: numberOfHours,
      thirstLevel: thirstLevel,
      isAlcoholicEvent: isAlcoholicEvent
    }
    const setLoading = isAlcoholicEvent ? setIsSelectingAlcoholic : setIsSelectingNonAlcoholic;
    setLoading(true);
    handleSubmit(data, (res) => handleSuccess(res, data), undefined, () => setLoading(false));
  }

  return (
    <div className="event-setup card-content">
      <h1>drink calculator</h1>
      <div className="main-description">
        Wanting to calculate how much alcohol you need for your event? 
        <br/>
        Tell us how many guests are celebrating, how tipsy they like to get + if you’re serving beer, wine, or liquor.
        <br/>
        We’ll do the calculations + plan your bar.
        <br/>
        Math is hard… Partying shouldn’t be. Bottoms up!
      </div>
      <div className="details-form">
        <div className="flex-column">
          Guests Attending?
          <ErrorDisplay error={errorState.NumberOfGuests}/>
          <Incrementor value={numberOfGuests} setValue={setNumberOfGuests} label="guests" maxVal={Number.MAX_SAFE_INTEGER} hasError={errorState.NumberOfGuests !== undefined}/>
        </div>
        <div className="flex-column">
          How long is the event?
          <ErrorDisplay error={errorState.NumberOfHours}/>
          <Incrementor value={numberOfHours} setValue={setNumberOfHours} label="hours" maxVal={6} maxValueError={`Event may only be up to 6 hours long.`}
            setError={(error) => {setErrorState({NumberOfHours: error})}} hasError={errorState.NumberOfHours !== undefined}/>
        </div>
        <div className="flex-column thirst-row">
          How thirsty is your crowd?
          <ErrorDisplay error={errorState.ThirstLevel}/>
          <Slider leftLabel="a little" rightLabel="a lot" value={thirstLevel} setValue={setThirstLevel} />
        </div>
        <div className="flex-column">
          What type of drink bar?
          <div className="centered full-width button-row">
            <LoadingButton loading={isSelectingNonAlcoholic} disabled={isSelectingAlcholic || isSelectingNonAlcoholic} 
              className="btn-primary" color="primary" onClick={() => createEvent(false)}>
                Non-Alcoholic
            </LoadingButton>
            <LoadingButton loading={isSelectingAlcholic} disabled={isSelectingAlcholic || isSelectingNonAlcoholic} 
              className="btn-primary" color="primary" onClick={() => createEvent(true)}>
                Alcoholic
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  )
};

export default EventSetup;