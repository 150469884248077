import "./Slider.scss";
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SliderBall, SliderDroplet } from "./CustomIcons";

function Slider({value, setValue, leftLabel, rightLabel, middleLabel, disabled, handleSelect, ...other}) { 
  const handleDragEnter = (event, val) => {
    event.preventDefault();
    setValue(val);
  }

  const handleClick = (e, val) => {
    if (!disabled) {
      handleSelect && handleSelect(e, val);
      setValue(val);
    }
  }

  const dragZoneFunctions = {
    onDragOver: (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    },
    onDrop: (e) => {
      e.preventDefault();
    }
  }

  const handleDragStart = (e) => {
    var img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer.setDragImage(img, 0, 0);
  }

  useEffect(() => {
    document.addEventListener("dragstart", handleDragStart, false);

    return () => {
      document.removeEventListener("dragstart", handleDragStart);
    }
  }, [])

  return (
    <div className={"slider-outer-wrapper " + (disabled ? "disabled" : "")} {...dragZoneFunctions}>
      <div className="slider-inner-wrapper">
        <div className="slider-left slider-control" onClick={(e) => handleClick(e, 1)} onDragEnter={(e) => handleDragEnter(e, 1)}>
          <div className="left-centered slider-line-wrapper">
            <div className="slider-line"></div>
            {value === 1 && <SliderDroplet height="20px" />}
          </div>
          <div className="left-label input-label slider-label">{leftLabel}</div>
        </div>
        <div className="slider-middle slider-control" onClick={(e) => handleClick(e, 2)} onDragEnter={(e) => handleDragEnter(e, 2)}>
          <div className="centered  slider-line-wrapper">
            <div className="slider-line"></div>
            {value === 2 && <SliderDroplet height="20px" />}
          </div>
          <div className="middle-label input-label slider-label">{middleLabel || " "}</div>
        </div>
        <div className="slider-right slider-control" onClick={(e) => handleClick(e, 3)} onDragEnter={(e) => handleDragEnter(e, 3)}>
          <div className="right-centered slider-line-wrapper">
            <div className="slider-line"></div>
            {value === 3 && <SliderDroplet height="20px" />}
          </div>
          <div className="right-label input-label slider-label">{rightLabel}</div>
        </div>
      </div>
    </div>
  );
}

Slider.propTypes = {
  value: PropTypes.number, 
  setValue: PropTypes.func, 
  leftLabel: PropTypes.string, 
  rightLabel: PropTypes.string, 
  middleLabel: PropTypes.string,
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func
}

export default Slider;