import React from 'react';
import { ModalBody } from 'reactstrap';
import ModalCloseButton from 'Components/ModalCloseButton';
import QuoteSuccessMessage from './QuoteSuccessMessage';

const QuoteFormSuccessModal = (props) => {
    const {toggle, ...other} = props;

    return (
        <div>
            <ModalBody className="quote-success-message-modal">
                <ModalCloseButton toggle={toggle} />  
                <div className="card-content">
                    <QuoteSuccessMessage/>
                </div>
            </ModalBody> 
        </div>
    );
};

export default QuoteFormSuccessModal;