import React, {useEffect} from 'react';
import {logout} from 'Utilities/AuthService';
import LoadingSection from 'Components/LoadingSection';

function LogOut(props){
	const {config} = props;

	useEffect(()=>{
		const LogOutUser = async ()=>{
			await logout(config);
			window.location = window.location.origin;
		};
		LogOutUser();
	}, []);

	return(<LoadingSection isLoading={true} loadingText="Redirecting"></LoadingSection>);
}

export default LogOut;