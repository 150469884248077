import "./NewTabLink.scss";
import React from 'react';
import PropTypes from 'prop-types';

function NewTabLink({url, className, onClick, children, ...others}) {
  return (
    <div className={"new-tab-link " + className} onClick={(e) => { onClick && onClick(e); window.open(url, "_blank")}} {...others}>
      {children}
    </div>
  )
}

NewTabLink.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default NewTabLink;