import './ShoppingList.scss';
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Alert, Modal, ModalBody } from 'reactstrap';
import Moment  from 'moment';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import {mapObjectEntries, compareOrderables} from 'Utilities/HelperFunctions';
import LoadingSection from 'Components/LoadingSection';
import RowDrink from './RowDrink';
import ShowSpecs from './ShowSpecs';
import SocialEventNameNotes from './SocialEventNameNotes'
import DeleteEventButton from './DeleteEventButton';
import QuoteForm from '../GetAQuote/QuoteForm';
import QuoteFormSuccessModal from '../GetAQuote/QuoteFormSuccessModal';
import ModalCloseButton from 'Components/ModalCloseButton';
import SendShoppingListContent from 'Features/DrinkCalculator/SendShoppingListContent';

function ShoppingList(props){
	const {config} = props;

    const {socialEventId} = useParams();
	const [apiWorker] = useApiWorker(config.baseUrl);

    //Social Event
	const [ eventName, setEventName ] = useState();
	const [ eventNotes, setEventNotes ] = useState();
	const [ eventDate, setEventDate ] = useState();
	const [ numberOfGuests, setNumberOfGuests ] = useState();
	const [ thirstLevel, setThirstLevel ] = useState();
	const [ numberOfHours, setNumberOfHours ] = useState();
	const socialEvent = {socialEventId, eventName, setEventName, eventDate, setEventDate, eventNotes, setEventNotes, numberOfGuests, setNumberOfGuests, thirstLevel, setThirstLevel, numberOfHours, setNumberOfHours};

    //Shopping List
    const [ drinkRecom, setDrinkRecom ] = useState([]);
    const [ essentialRecom, setEssentialRecom ] = useState([]);
    const [, setBlogLinks ] = useState([]);
    const [ isShoppingListLoading, setIsShoppingListLoading ] = useState(true);
	const [ isSocialEventInfoLoading, setIsSocialEventInfoLoading ] = useState(true);
	
	//Get a Quote
	const [ quoteModalOpen, setQuoteModalOpen] = useState(false);
	const toggleQuoteModal = () => setQuoteModalOpen(!quoteModalOpen);
	const [ quoteSuccessModalOpen, setQuoteSuccessModalOpen] = useState(false);
	const toggleSuccessQuoteModal = () => setQuoteSuccessModalOpen(!quoteSuccessModalOpen);

	//Send Shopping List
	const [sendModalOpen, setSendModalOpen] = useState(false);
	const toggleSendModal = () => setSendModalOpen(!sendModalOpen);

    const onChangeRating = (row) => {
    	drinkRecom.filter((x)=>{return x.id == row.id})[0].rating = row.rating;
    	setDrinkRecom(drinkRecom);
    }

    const onChangeNotes = (row) => {
    	drinkRecom.filter((x)=>{return x.id == row.id})[0].notes = row.notes;
    	setDrinkRecom(drinkRecom);
    }

	const onClickSave = (props)=>{
		const {setSaveButtonLoading} = props;

		var eventChanges = {
		  id: socialEventId,
		  name: eventName,
		  notes: eventNotes,
		  drinkNotes: [] 
		};		  
		drinkRecom.forEach((drink)=>{
			eventChanges.drinkNotes.push({
				id:drink.id,
				rating: drink.rating,
				notes: drink.notes,
			});
		});

		setSaveButtonLoading(true);
		apiWorker.post(`${ENDPOINTS.shoppingList.saveNotes}`, eventChanges).then(()=>{
			setAlert({
				isOpen: true,
				message: "Your changes have been saved!",
				color:"success"
			});
		}, ()=>{
			setAlert({
				isOpen: true,
				message: 'Something was wrong!',
				color:"danger"
			});
		});	
		setSaveButtonLoading(false);

	}

    useEffect(()=>{
    	const getSocialEventInfo = async ()=>{
			var resp = await apiWorker.get(`${ENDPOINTS.socialEvents.main}/${socialEventId}`);
			setEventName(resp.data.name);
			setEventNotes(resp.data.notes);
			setEventDate(Moment(resp.data.dateCreated, 'YYYY-MM-DD').format('MM.DD.YY'));
			setNumberOfGuests(resp.data.numberOfGuests);
			setNumberOfHours(resp.data.numberOfHours);
			setThirstLevel(resp.data.thirstLevel.value);
			setIsSocialEventInfoLoading(false);
    	}
    	const getShoppingList = async ()=>{
			var resp = await apiWorker.get(`${ENDPOINTS.shoppingList.main}/${socialEventId}`);
			setDrinkRecom(resp.data.recommendations);
			setEssentialRecom(resp.data.essentialItems);
			setBlogLinks(resp.data.blogLinks);
			setIsShoppingListLoading(false);
    	}

    	getSocialEventInfo();
    	getShoppingList();
    }, []);

	const isLastEntry = (index, obj) => {
		if(Array.isArray(obj)) {
		  return index === obj.length - 1;
		}
		return index === Object.entries(obj).length - 1;
	}

    const [alert, setAlert] = useState({
    	isOpen: false,
    	color: '',
    	message: ''
    });

    const alertToggle = ()=>{setAlert({isOpen:false})}

	return(
		<div className="shopping-list">
			<Row>
				<Col lg={4} md={12} sm={12} xs={12} >
					<ShowSpecs {...socialEvent} onClickSave={onClickSave} isSocialEventInfoLoading={isSocialEventInfoLoading}/>
				</Col>
				<Col lg={{size:7, offset:1}} md={11} sm={11} xs={11} className="shopping-list-area">
					<Alert {...alert} toggle={alertToggle}>
						{alert.message}
					</Alert>
					<SocialEventNameNotes {...socialEvent} isSocialEventInfoLoading={isSocialEventInfoLoading} />
					<LoadingSection isLoading={isShoppingListLoading}>
						<h3 className="shopping-list-title">Your Shopping List</h3>
						<div className="print-only-info full-width">
							<h5 className="centered">{socialEvent.eventName}</h5>
							<div className="font-italic centered">
								{socialEvent.eventNotes}
							</div>
							<div className="sub-heading centered">For {socialEvent.numberOfGuests} guests, you need:</div>
						</div>	
					    <div className="card-content">						
						{
							mapObjectEntries(drinkRecom, compareOrderables, ([, recommendation], index) => 
								<RowDrink recommendation={recommendation} isLastEntry={isLastEntry(index, drinkRecom)} key={index} onChangeRating={onChangeRating} onChangeNotes={onChangeNotes}/>
							)
						}
							
						</div>
						<div className="button-row centered shopping-list-actions">
							<button className="btn btn-primary" onClick={()=>{window.print()}}>Download</button>
							<button className="btn btn-primary" onClick={()=>{toggleSendModal()}}>Send</button>
							<button className="btn btn-primary" onClick={()=>{toggleQuoteModal()}}>Get a Quote</button>
						</div>
						<DeleteEventButton {...socialEvent} apiWorker={apiWorker} setAlert={setAlert}/>	
					</LoadingSection>
				</Col>
			</Row>

			<Modal isOpen={quoteModalOpen} className="store-locator-modal-wrapper" toggle={toggleQuoteModal} backdrop={true} backdropClassName="primary-backdrop">
				<ModalBody>
					<ModalCloseButton toggle={toggleQuoteModal} set/>
					<QuoteForm config={config} setIsModalShowing={setQuoteModalOpen} setSuccessQuoteModalShowing={setQuoteSuccessModalOpen} recommendations={drinkRecom} essentials={essentialRecom} socialEventId={socialEventId}/>
				</ModalBody>
			</Modal>
			<Modal isOpen={quoteSuccessModalOpen} className="store-locator-modal-wrapper" toggle={toggleSuccessQuoteModal} backdrop={true} backdropClassName="primary-backdrop">
				<QuoteFormSuccessModal toggle={toggleSuccessQuoteModal}/>
			</Modal>

			<Modal isOpen={sendModalOpen} className="store-locator-modal-wrapper" toggle={toggleSendModal} backdrop={true} backdropClassName="primary-backdrop">
				<ModalBody>
					<ModalCloseButton toggle={toggleSendModal} set/>
					<SendShoppingListContent config={config} setIsModalShowing={setSendModalOpen} sentSocialEventId={socialEventId}/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default ShoppingList;