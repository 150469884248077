import './QuoteSuccessMessage.scss';
import React from 'react';
import {loggedIn} from 'Utilities/AuthService';
import Render from 'Components/Render';
import NewTabLink from 'Components/NewTabLink';
import SimpleLink from 'Components/SimpleLink';
import { URLS, ROUTES } from 'Utilities/Constants';

function QuoteSuccessMessage(){
	return(
		<div className="quote-success">
			<Render condition={loggedIn()}>
	            <p className="paragraph">
            		We'll be in touch soon with your quote. In the meantime, you can <NewTabLink url={URLS.BLOG_MAIN}>get thirsty</NewTabLink>, visit your <SimpleLink url={ROUTES.liquorCabinet}>liquor cabinet</SimpleLink>, or <SimpleLink url={ROUTES.eventSetup}>plan another bar.</SimpleLink>
	            </p>
	            <h2>Cheers!</h2>
			</Render>
			<Render condition={!loggedIn()}>
				<p className="paragraph">
					We'll be in touch soon with your quote. In the mean time why don't you <SimpleLink url={ROUTES.signup}>join us</SimpleLink>, or continue as a guest and <SimpleLink url={ROUTES.eventSetup}>create your next best party experience</SimpleLink> to get a quote.
				</p>  
			</Render>	
		</div>
	);
}

export default QuoteSuccessMessage;