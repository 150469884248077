import React from 'react';
import { Button } from 'reactstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from 'prop-types';

function LoadingButton({loading, children, ...others}) {
  return (
    <Button disabled={loading} {...others}>
      {loading && <PulseLoader color={"#ffffff"} loading={loading} size={12} margin={8} />}
      {!loading && children}
    </Button>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
}

export default LoadingButton;