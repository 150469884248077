import './SimpleLink.scss';
import React from 'react';
import { useHistory } from 'react-router-dom';

function SimpleLink({url, children, ...others}){
	let history = useHistory();
	const onClick = ()=>{
		history.push(url);
	}
	return(
		<a onClick={onClick} className="simple-link" {...others}>{children}</a>
	);
}

export default SimpleLink;