import {useState, useContext} from 'react';
import axios from 'axios';
import {ROUTES} from 'Utilities/Constants';
import {Context} from 'Context/Store';
import {getToken} from 'Utilities/AuthService';

export const useApiWorker = (baseUrl) => {
  const token = getToken();

  const apiWorker = axios.create({
    baseURL: baseUrl,
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : null
    }
  });

  return [apiWorker];
}

export const useSubmitHandler = (endpoint, baseUrl) => {
  const [response, setResponse] = useState({});
  const [errorState, setErrorState] = useState(false);
  const [apiWorker] = useApiWorker(baseUrl);

  const handleSubmit = async(data, successCallback, errorCallback, completeCallback) => {
    let res = await apiWorker.post(endpoint, data)
      .catch((error) => {
        const {data} = error.response || {};
        if(data && data.errors) {
          console.log(data.errors);
          setErrorState(data.errors);
        }
        else if(data && error.response.status == 400){
          setErrorState({BadRequest: data});
        }
        errorCallback && errorCallback(error.response);
      });
    if(res !== undefined) {
      setErrorState({});
      setResponse(res);
      successCallback && successCallback(res);
    }

    completeCallback && completeCallback(res);
  }

  return [handleSubmit, {response, errorState, apiWorker, setErrorState}];
}

export const useDrinkSyleRouter = () => {
  const makeStyleUrl = (id) => {
    return `${ROUTES.styleSelectionNoParams}/${id}`;
  }

  const getPrevAndNextUrl = (drinkTypes, currentDrinkTypeId) => {
    var nextUrl = ROUTES.results,
        prevUrl = ROUTES.drinkSelection;
    var selectedTypeIds = drinkTypes.filter(type => type.isSelected && type.hasStyles).map(t => t.id);
    if(!selectedTypeIds.length || selectedTypeIds.length === 0) {
      return {nextUrl, prevUrl};
    }

    var currIndex = selectedTypeIds.indexOf(currentDrinkTypeId);
    
    nextUrl = makeStyleUrl(selectedTypeIds[currIndex+1]);
    
    if(currIndex === 0 && selectedTypeIds.length === 1) {
      nextUrl = ROUTES.results;
    }
    if(currIndex > 0) {      
      if(currIndex === selectedTypeIds.length - 1) {
        nextUrl = ROUTES.results;
      }
      
      prevUrl = makeStyleUrl(selectedTypeIds[currIndex-1]);
    }

    return {nextUrl, prevUrl};
  }

  return [getPrevAndNextUrl];
}

export const useCalculatorContext = () => {
  const [state, dispatch] = useContext(Context);
  const sendUpdate = async (type, payload) => {
    return await dispatch({type: type, payload: payload});
  }

  return [state, sendUpdate];
}