import decode from 'jwt-decode';
import { discourseLogOut } from './DiscourseService';

export const loggedIn =()=>{
    const token = getToken();
    return !!token && !isTokenExpired(token);
}

export const isTokenExpired = (token) => {
    try {
        const decoded = decode(token);
        // Checking if token is expired.
        if (decoded.exp < Date.now() / 1000) { 
            return true;
        }
        else
            return false;
    }
    catch (err) {
        return false;
    }
}

export const setToken = (idToken) => {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
}

export const getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
}

export const logout = async (config) => {
    // Clear user token and profile data from localStorage
    const token = getToken();
    await discourseLogOut({config, token});
    localStorage.removeItem('id_token');

}

export const getProfile = () => {
    // Using jwt-decode to decode the token
    try{
        return decode(getToken());
    }catch (err){
        return {nameid:null};
    }

}

