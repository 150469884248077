import './QuoteForm.scss';
import React, {useState, useEffect} from 'react';
import { Row, Col} from 'reactstrap';
import LoadingButton from 'Components/LoadingButton';
import CheckableBox from 'Components/CheckableBox';
import FormInput from 'Components/FormInput';
import Render from 'Components/Render';
import LoadingSection from 'Components/LoadingSection';
import ErrorDisplay from 'Components/ErrorDisplay';
import { listToObj, mapObjectEntries, compareOrderables } from 'Utilities/HelperFunctions';
import { useApiWorker, useSubmitHandler, useCalculatorContext } from 'Utilities/CustomHooks';
import { ROUTES, ENDPOINTS, SHOPPING_LIST_OPTION_KEYS } from 'Utilities/Constants';
import {Input} from 'reactstrap';
import {loggedIn, getProfile} from 'Utilities/AuthService';
import {useHistory, useLocation} from 'react-router-dom';
import ActionTypes from 'Context/ActionTypes';
import Checkbox from 'Components/Checkbox';


function QuoteForm(props){
    const {config, setIsModalShowing, setSuccessQuoteModalShowing, recommendations, essentials, socialEventId} = props;
    const [state, sendUpdate] = useCalculatorContext(); 
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [zip, setZip] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [handleSubmit, {errorState}] = useSubmitHandler(ENDPOINTS.getaquote.post, config.baseUrl);
    const [, setErrorMessage] = useState("");
    const [apiWorker] = useApiWorker(config.baseUrl);
    const [deliveryTypes, setDeliveryTypes] = useState(listToObj(state.deliveryTypes || []));
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [, setIsSectionLoading] = useState(true);
    const [isShoppingListLoading, setIsShoppingListLoading] = useState(true);
    const [shoppingList, setShoppingList] = useState("");
    const [selectedType, setSelectedType] = useState();
    const [address, setAddress] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [] = useState("");
    const [selectedEventId, setSelectedEventId] = useState("");
    const [userSavedEvents, setUserSavedEvents] = useState([]);
    const [socialEvent, setSocialEvent] = useState(state.userEvent || {});
    const [drinkRecommendations, setDrinkRecommendations] = useState(recommendations);
    const [otherEssentials, setOtherEssentials] = useState(essentials);
    const [userDataRequested, setUserDataRequested] = useState(false);
    const [originalShoppingList, setOriginalShoppingList] = useState("");
    const [ownList, setOwnList] = useState(!state.eventCreated);
    const [newList] = useState(state.eventCreated);
    const [isStoreLocator, setIsStoreLocator] = useState(false);
    const [showShopListLabel, setShowShopListLabel] = useState(false);
    const [newsLetterSubscription, setNewsLetterSubscription] = useState(false);
    const [isLiquorCabinet, setIsLiquorCabinet] = useState(false);
    const [shoppingListOptions, setShoppingListOptions] = useState({
        OwnList: ownList,
        PastList: false,
        NewList: newList
    });

    let history = useHistory();
    let location = useLocation();

    // HANDLE DELIVERY TYPE CHANGE
    const setIsSelected = (key) => {
        setDeliveryTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], isSelected: !prevTypes[key].isSelected}}));
    }
    const resetDeliverySelection = () => {
        for(let i in deliveryTypes){
            deliveryTypes[i].isSelected = false;
        }
    }
    const handleBoxClick = (e, id) => {
        resetDeliverySelection();
        setIsSelected(id);
        // set the state for the one is submitted
        setSelectedType(Number(id));
    }

    // CREATE NEW SHOPPING LIST
    const createNewEvent = async () => {
        handleShoppingListOptionChange(SHOPPING_LIST_OPTION_KEYS.newList);
        await sendUpdate(ActionTypes.clearEventPersistStoreInfo, null);
        history.push(ROUTES.eventSetup);
    }

    // SELECT PAST SHOPPING LIST
    const handleShoppingListChange = (e) => {
        setOwnList(false);
        setShoppingList(e.target.value);
    }

    const setEventShoppingList = async (value) => {
       
        setSelectedEventId(value);
        // Set the shopping recommendations and essentials of the selected event in the dropdown
        if (value !== ""){
            var resp = await apiWorker.get(`${ENDPOINTS.socialEvents.userEvent}/${value}`);
            const data = resp.data || {};
            handleShoppingListOptionChange(SHOPPING_LIST_OPTION_KEYS.pastList);
            setSocialEvent(data);
            setDrinkRecommendations(data.recommendations);
            setOtherEssentials(data.essentialItems);
        }
    }

    // WRITE OWN SHOPPING LIST
    const handleOwnListClick = () => {
        handleShoppingListOptionChange(SHOPPING_LIST_OPTION_KEYS.ownList);
        setOwnList(true);
        setShoppingList("");
        setEventShoppingList("");
        setShowShopListLabel(false);
    }

    // HANDLE SHOPPING LIST OPTIONS
    const handleShoppingListOptionChange = (keytoFind) =>{
        Object.keys(shoppingListOptions).forEach(function(index) {
            shoppingListOptions[index] = false
        });
        setShoppingListOptions(prevState => ({...prevState, [keytoFind]:true}))
    }

    const handleSuccess = (res) => {
        const {data} = res;
        setIsSubmitting(false); 
        if(data.emailSuccess) {
          setIsModalShowing(false); 
          setSuccessQuoteModalShowing(true);
        } else {
          setErrorMessage(res.errorMessage);
        }
      }

    const sendQuote = async () => {
        setIsSubmitting(true);

        var data = {
           ...socialEvent,
          phoneNumber: phoneNumber, emailAddress: emailAddress, name: name, zipCode: zip, 
          deliveryTypeId: selectedType, ShoppingList: shoppingList, streetAddress: address, date: date, time: time,
          isOwnList: shoppingListOptions.OwnList, isLogged: loggedIn(),
          storeInformation: state.storeInfo, selectedEventId: selectedEventId, eventCreated: state.eventCreated,
          newsLetterSubscription: newsLetterSubscription,
          isLiquorCabinet: isLiquorCabinet, socialEventId: socialEventId || socialEvent.id
        }
        if (originalShoppingList !== "" && shoppingList !== originalShoppingList)
            data.customized = true;
        
        await handleSubmit(data, (res) => {handleSuccess(res)}, ({data}) => {setIsSubmitting(false); setErrorMessage(data.ErrorMessage)});
        window.scrollTo(0, 0);
    }

    const defaultTypeSelected = (obj) => {
        let result =0;
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key].isSelected) {
                result = obj[key].id;
            }
        }
        return result;
    }

    const populateShoppingList = () => {
        // Get recommendations and other essentials and build the string for the text area
        let shoplist ="";
        for (let k in drinkRecommendations){
            if(drinkRecommendations.hasOwnProperty(k)){
                shoplist += `${drinkRecommendations[k].amountFullText} of ${drinkRecommendations[k].sponsorProductDrinkStyleName || drinkRecommendations[k].name || k } (${drinkRecommendations[k].sponsorProductName})\n`;
            }
        }

        if(otherEssentials){ 
            shoplist += "\nOther Essentials:"
            otherEssentials.map(e => 
            shoplist += `\n${e.amount} ${e.name}`);}
        
        setShoppingList(shoplist);
        setOriginalShoppingList(shoplist);
    }

    useEffect(() => {
        setIsStoreLocator(location.pathname.toLowerCase().includes("storelocator"));

        if(location.pathname.toLowerCase().includes("shoppinglist")){
            setIsLiquorCabinet(true);
            handleShoppingListOptionChange(SHOPPING_LIST_OPTION_KEYS.newList);
        }
    }, [isStoreLocator, isLiquorCabinet])

    useEffect(() => { 
        // Populate shopping list text area
        setIsShoppingListLoading(true);
        const getShoppingList = () => {
            if((drinkRecommendations.length > 0 && drinkRecommendations) || (otherEssentials.length > 0 && otherEssentials)){
                populateShoppingList();
                setIsShoppingListLoading(false);
                setShowShopListLabel(true);
            }else{
                setShowShopListLabel(false);
            }
        }
        getShoppingList();

        if(!shoppingList)
            setIsShoppingListLoading(false);

    }, [drinkRecommendations, otherEssentials]);

    useEffect(() => {
        // Get the checkable box delivery options 
        const getDeliveryTypes = async () => {
            const res = await apiWorker.get(ENDPOINTS.getaquote.getDeliveryTypes);
            const data = res.data || {};
            const objTypes = listToObj(data);

            setDeliveryTypes(objTypes);
            setSelectedType(defaultTypeSelected(objTypes));

            setIsPageLoading(false);
        }

        setIsPageLoading(true);
        if(state.deliveryTypes === undefined || state.deliveryTypes.length == 0) {
          getDeliveryTypes();
        } else {
          setIsPageLoading(false);
        }
        
    }, [state.deliveryTypes]);

    useEffect(() => {
        // Get social events from the logged user that populate dropdown options
        const getEventsData = async () => {
            const user = getProfile();
            var resp = await apiWorker.get(`${ENDPOINTS.socialEvents.options}/${user.nameid}`);
            const data = resp.data || {};
            if(data){
                setUserSavedEvents(data);
            }
            setUserDataRequested(true);
            setIsSectionLoading(false);
        }

        setIsSectionLoading(true);
        // Only if user is logged and is not getting quote after creating an event in drink calculator
        if(loggedIn() && isStoreLocator && userSavedEvents.length == 0 && !userDataRequested){
            getEventsData();
        }else{
            setIsSectionLoading(false);
        }
    }, [userSavedEvents, isStoreLocator]);
    
    return (
        <div className="card-content get-a-quote-modal">
            <h1 className="centered quote-title">
                get a quote
            </h1>
            <div className="full-width centered quote-description-first">
                Enter your details + fine tune your bar list.
            </div>
            <div className="get-a-quote-description">
                Not sure about anything? Leave it blank. We're here to help! We'll reach out soon to go over your quote + answer any questions.
             </div>
             <Row className="user-data-form">
                <FormInput value={name} setValue={setName} name="name" id="name-input" placeholder="name*"
                    errorMessage={errorState["Name"]} colSize ="col-md-6" />
                <FormInput value={phoneNumber} setValue={setPhoneNumber} name="phone" autoComplete="tel" id="phone-number-input"
                    onValueChange={(values) => setPhoneNumber(values.value)} format="(###) ###-####" isNumberFormat={true} useUnformattedValue={true} placeholder="phone number"
                    errorMessage={errorState["PhoneNumber"]} colSize ="col-md-6" />
                <FormInput value={emailAddress} setValue={setEmailAddress} name="email" autoComplete="email" id="email-input" placeholder="email address*"
                    errorMessage={errorState["EmailAddress"]} colSize ="col-md-6" />
                <FormInput value={zip} setValue={setZip} name="zip" id="zip-input" placeholder="zip*"
                    errorMessage={errorState["ZipCode"]} colSize ="col-md-6" />
            </Row>
            <LoadingSection isLoading={isPageLoading}>
                <Row>
                {
                    mapObjectEntries(deliveryTypes, compareOrderables, ([id, type]) => 
                        <Render key={id}>
                        <Col xl={4} lg={4} med={4} sm={4} xs={12} key={id} className="drink-select-column delivery-type">
                            <div className="column-wrapper centered">
                            <CheckableBox outlined={true} short={true} selected={type.isSelected} handleClick={(e) => {handleBoxClick(e, id)}}>
                                <div className="full-width centered">
                                {type.name}
                                </div>             
                            </CheckableBox>
                            </div>
                        </Col>                
                        </Render>
                    )
                }
                </Row>
            </LoadingSection>

            <Render condition={selectedType !== 3 && selectedType !== undefined}>
                <Row className="quote-sub-title-wrapper gray-box-wrapper centered">
                    <div className="quote-sub-title full-width centered">
                        Optional
                    </div>
                    
                    <Render condition={selectedType === 1}>
                        <div className="centered">enter your delivery address + preferred timing here.</div>
                        <FormInput value={address} setValue={setAddress} name="address" id="address-input" placeholder=" street address"
                        errorMessage={errorState["StreetAddress"]} colSize ="col-md-12" />  
                    </Render>
                    <Render condition={selectedType === 2}>
                        <div className="centered">enter your preferred pickup date + time here.</div>
                    </Render>                       

                    <FormInput value={date} setValue={setDate} name="date" id="date-input" placeholder="MM/DD/YY"
                    onValueChange={(values) => setDate(values.value)} format="##/##/##" isNumberFormat={true} useUnformattedValue={false}
                    errorMessage={errorState["Date"]} colSize ="col-md-6" /> 
                    <FormInput value={date} setValue={setTime} name="time" id="time-input" placeholder="12:00"
                    onValueChange={(values) => setTime(values.value)} format="##:##" isNumberFormat={true} useUnformattedValue={false}
                    errorMessage={errorState["Time"]} colSize ="col-md-6" />  
                </Row>
            </Render>
            <h3 className="shopping-list-title centered">
               shopping list
            </h3>
            <div className={"shopping-list-options" + (!isStoreLocator ? " hidden" : "")}>
                    <div id="CalculateList" className={"shopping-list-option-tab centered " + (shoppingListOptions.NewList ? "active": "")} onClick={createNewEvent}>
                        <div className="box-content">
                            <div className="full-width centered">
                                Calculate a new list
                            </div>
                        </div>
                    </div>
                    {/* Render only if user is logged is in store locator and has any past events/shopping lists */}
                    <Render condition={loggedIn() && isStoreLocator && userSavedEvents.length > 0}>
                        {/* check if user has events and render dropdown if any */}
                        <FormInput type="select" name="select" id="user-event-select"
                        className={(shoppingListOptions.PastList ? "active": "")}
                        value={selectedEventId} setValue ={(value) => setEventShoppingList(value)}
                        defaultValue = "" colSize ="col-md-12"
                        errorMessage={errorState["SelectedEventId"]}>
                            <option value="">Select a past list</option>
                            {userSavedEvents.map((event) => {
                                return(
                                    <option className="option-value" value={event.id} key={event.id}>{event.name}</option>
                                )                        
                            })}
                        </FormInput>
                    </Render>
                    <div id="WriteList" className={"shopping-list-option-tab centered " + (shoppingListOptions.OwnList ? "active": "")} onClick={handleOwnListClick}>
                        <div className="box-content">
                                <div className="full-width centered">
                                    Write my own list
                                </div>
                            </div>
                        </div>
                </div>
            <div id="shop-list-label" className={"full-width " + (showShopListLabel ? "active" : "")}>
                <label>
                    Edit your shopping list here. You can adjust quantities, delete items, tell us your brand preferences + leave us a note!
                </label>
            </div>
            <div className="centered">

                <LoadingSection isLoading={isShoppingListLoading}>
                    <Input className="shop-list-text" type="textarea" id="shopping-list" value={shoppingList} onChange={handleShoppingListChange}/>
                </LoadingSection>
            </div>
            <ErrorDisplay error={errorState["ShoppingList"]}/>
            <Render condition={!loggedIn()}>
                <div className="centered news-letter full-width">
                    <Checkbox value={newsLetterSubscription} setValue={setNewsLetterSubscription}>
                        <label className="news-letter-subs-message">
                            Put me on the list! Click here & we'll keep you in the loop on what we're doing at The Drink Calculator. You definitely won't get spammed, but you just might get a jello shot.
                        </label>
                    </Checkbox>
                </div>
            </Render>
            <div className="centered">
                <LoadingButton loading={isSubmitting} onClick={sendQuote}
                className="btn-primary" color="primary">
                    Submit
                </LoadingButton>
            </div>
        </div>
    );

};

export default QuoteForm;
