import React from 'react';
import LabelEditable from 'Components/LabelEditable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/free-solid-svg-icons';

function EditUserInfoTable({userName, email, firstName, setFirstName, lastName, setLastName, phoneNumber, setPhoneNumber, zipCode, setZipCode}){
	return(
		<table className="table input-label pull-left">
			<tbody>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faUser}/><span className="ml-2">User Name</span></td>
					<td>{userName}</td>
				</tr>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faEnvelope}/><span className="ml-2">Email</span></td>
					<td><a href="mail:{email}">{email}</a></td>
				</tr>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faIdCard}/><span className="ml-2">First Name</span></td>
					<td><LabelEditable value={firstName} setValue={setFirstName} placeholder="What's your first name?" /></td>
				</tr>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faIdCard}/><span className="ml-2">Last Name</span></td>
					<td><LabelEditable value={lastName} setValue={setLastName} placeholder="What's your last name?" /></td>
				</tr>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faMobileAlt}/><span className="ml-2">Phone Number</span></td>
					<td><LabelEditable format="(###) ###-####" isNumberFormat={true} value={phoneNumber} setValue={setPhoneNumber} placeholder="What's your phone number?" /></td>
				</tr>
				<tr>
					<td><FontAwesomeIcon icon={Icon.faMapMarked}/><span className="ml-2">ZIP Code</span></td>
					<td><LabelEditable value={zipCode} setValue={setZipCode} placeholder="What's your zip code?" /></td>
				</tr>
			</tbody>
		</table>
	);
}

export default EditUserInfoTable;