import axios from 'axios';
import { DISCOURSE_PATHS, ENDPOINTS } from 'Utilities/Constants';
	
let _autovalidateSso = true;

export const discourseLogOut = async ({config, token}) => {
	var url = `${config.baseUrl}${ENDPOINTS.discourse.Logout}`;
	var headers = {
		headers:{
			"Authorization": `Bearer ${token}`
		}
	}
	
	try{
		const discourseUrl = await axios.post(url, {}, headers);
	}catch (e){
		console.error('discourseLogOut', e);
	}
}

export const cancelAutoValidateSso = () => {
	_autovalidateSso = false;
}

export const autovalidateSso = () => {
	return _autovalidateSso === true;
}