import './AccountIcon.scss';
import React from 'react';
import droplet from '../Assets/droplet.svg';

function AccountIcon(props){
    const {handleClick, ...other} = props;
   
    return(
        <div className="account-circle" onClick={() => {handleClick !== undefined && handleClick()}}>
            <img src={droplet} className="account-droplet"/>
        </div>
    )
}

export default AccountIcon;