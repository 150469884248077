import './DrinkCalculator.scss';
import React from 'react';
import LoadingButton from 'Components/LoadingButton';
import { ModalBody } from 'reactstrap';
import ModalCloseButton from 'Components/ModalCloseButton';
import NewTabLink from 'Components/NewTabLink';
import { URLS } from 'Utilities/Constants';

const ShoppingListSuccessModal = (props) => {
  const {toggle, ...other} = props;

  return (
    <ModalBody>
      <ModalCloseButton toggle={toggle} />  
      <div className="card-content send-shopping-list-modal">
        <h1 className="card-heading results-heading">
          Cheers!
          <div className="full-width centered sub-heading">
            Your shopping list has been sent.
          </div>
        </h1>
        <div className="full-width centered sub-heading">
          <NewTabLink url={URLS.BLOG_MAIN}>
            <LoadingButton loading={false} disabled={false} onClick={toggle}
              className="btn-secondary" color="primary">
              Get thirsty
            </LoadingButton>
          </NewTabLink>
        </div>
      </div>
    </ModalBody>
  )
};

export default ShoppingListSuccessModal;