import "./LabelEditable.scss";
import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ErrorDisplay from "./ErrorDisplay";
import Render from "./Render";

function LabelEditable({value, setValue, label, errorMessage, isNumberFormat=false, useUnformattedValue=false, ...other}) { 
  const { labelTop } = other;
  const handleChange = (e) => {
    if(!useUnformattedValue) {
      setValue && setValue(e.target.value);
    }
  }

  const onFocus = (event) => {
    event.target.select();
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      console.log('delete');
    }
  }

  return (
    <div className="label-editable">
      <Render condition={labelTop}>
        <div className="input-label">{labelTop}</div>
      </Render>
      <div className="input-content">
        <div className={"value-display"}>
          {
            isNumberFormat 
            ? <NumberFormat customInput={Input} defaultValue={value} value={value} onKeyUp={handleChange} onKeyDown={onKeyDown} {...other} onFocus={onFocus} />
            : <Input defaultValue={value} onKeyUp={handleChange} onKeyDown={onKeyDown} {...other} onFocus={onFocus} />
          }
        </div>
      </div>
      <Render condition={label}>
        <div className="input-label">{label}</div>
      </Render>
      <ErrorDisplay error={errorMessage}/>
    </div>
  );
};

LabelEditable.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string)
}

export default LabelEditable;