import React, {Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'Utilities/Constants';
import { useCalculatorContext } from 'Utilities/CustomHooks';
import Render from 'Components/Render';

export default function RefreshRedirect({children}) {
  const [state] = useCalculatorContext();

  return (
    <Fragment>
      <Render condition={state.eventCreated}>
        {children}
      </Render>
      <Render condition={!state.eventCreated}>
        <Redirect to={ROUTES.eventSetup} />
      </Render>
    </Fragment>
  );
}