import './RecoveryPassword.scss';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import InputBlock from 'Components/InputBlock.js';
import LoadingButton from 'Components/LoadingButton';
import Render from 'Components/Render';
import { Alert } from 'reactstrap';
import PasswordChangedSuccess from './PasswordChangedSuccess'

function RecoveryPassword(props)
{
	const {config} = props;
	const [apiWorker] = useApiWorker(config.baseUrl);
	const {emailAddress, requestId} = useParams();

	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [isPasswordChanged, setIsPasswordChanged] = useState(false);

	const [isLoading] = useState();

    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
    	color: '',
    	message: []
    });

    const alertToggle = (value)=> {
    	setAlert(value);
    };

    const onClick = async ()=>{
    	try{
    		await apiWorker.post(ENDPOINTS.user.recoveryPassword, {
    			emailAddress: emailAddress,
    			requestId: requestId,
    			newPassword: password,
    			confirmPassword: confirmPassword
    		});
    		setIsPasswordChanged(true);
    	}catch(e){
    		if (e.response && e.response.data && e.response.data.errors) {
	    		var errorMessage = [];
    			const errorKeys = Object.keys(e.response.data.errors);
    			errorKeys.map((key)=>{
    				e.response.data.errors[key].map((msg)=>{
    					errorMessage.push(msg);
    				});
    			});
   				alertToggle();
    			setAlertConfig({
					color: 'danger',
					message: errorMessage
    			});
    		}else{
				alertToggle();
    			setAlertConfig({
					color: 'danger',
					message: ["Something was wrong!"]
    			});
    		}
    	}
    }

	return(
		<div className="recovery-password">
			<Render condition={!isPasswordChanged}>
				<h2>reset password</h2>
				<Alert isOpen={alert} color={alertConfig.color} toggle={()=>alertToggle(false)} className="alert full-width">
					<ul>
					{
						alertConfig.message.map((msg)=>{
							return(<li>{msg}</li>)
						})
					}
					</ul>
				</Alert>
				<div className="inputs-group">
					<InputBlock type="password" value={password} setValue={setPassword} 
						name="password" placeholder="password"/>
					<InputBlock type="password" value={confirmPassword} setValue={setConfirmPassword} 
					name="confirmPassword" placeholder="confirm password"/>
				</div>
				<div className="centered">
					<LoadingButton loading={isLoading} color="primary" onClick={onClick}>
						Confirm					
					</LoadingButton>
				</div>
			</Render>
			<Render condition={isPasswordChanged}>
				<PasswordChangedSuccess {...props}/>
			</Render>
		</div>
	);
}

export default RecoveryPassword;