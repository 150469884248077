import './DrinkCalculator.scss';
import React, {useState, useEffect, Fragment} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import LoadingButton from 'Components/LoadingButton';
import CheckableBox from 'Components/CheckableBox';
import ActionTypes from 'Context/ActionTypes';
import { ENDPOINTS } from 'Utilities/Constants';
import {objToList, listToObj, mapObjectEntries, compareOrderables} from 'Utilities/HelperFunctions';
import {useSubmitHandler, useDrinkSyleRouter, useCalculatorContext} from 'Utilities/CustomHooks';
import RefreshRedirect from './RefreshRedirect';
import LoadingSection from 'Components/LoadingSection';
import Render from 'Components/Render';
import ArrowButton from 'Components/ArrowButton'

function DrinkStyleSelection(props) {
  const {config, ...other} = props;

  let history = useHistory();
  let {drinkTypeId} = useParams();
  const [state, sendUpdate] = useCalculatorContext();
  const [drinkStyles, setDrinkStyles] = useState(listToObj(state.drinkStyleSelections ? state.drinkStyleSelections[drinkTypeId] || [] : []));
  const [titleParts, setTitleParts] = useState([]);
  const [isMixers, setIsMixers] = useState(state.mixerTypeId == drinkTypeId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [getPrevAndNextUrl] = useDrinkSyleRouter();

  const [, {apiWorker}] = useSubmitHandler(ENDPOINTS.drinkStyles.validate, config.baseUrl);

  const setIsSelected = (key) => {
    setDrinkStyles(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], isSelected: !prevTypes[key].isSelected}}));
  }

  const getUpdatePayload = (drinkList) => {
    return {drinkTypeId: drinkTypeId, styleList: drinkList, isMixers: isMixers};
  }

  const saveAndRedirect = async (shouldGoBack, drinkList) => {
    await sendUpdate(ActionTypes.setDrinkStyles, getUpdatePayload(drinkList));
    var {nextUrl, prevUrl} = getPrevAndNextUrl(state.drinkTypeSelections || [], drinkTypeId);
    if(shouldGoBack) {
      history.push(prevUrl);
    } else {
      history.push(nextUrl);
    }
  }

  const addDrinkStyles = async () => {
    const drinkList = objToList(drinkStyles);
    if(!drinkList.some(d => d.isSelected)) {
      skipStyle();
      return;
    }

    saveAndRedirect(false, drinkList);
  }

  const prevPage = async () => {
    await saveAndRedirect(true, objToList(drinkStyles));
  }

  const skipStyle = async () => {
    var drinkList = objToList(drinkStyles);
    await saveAndRedirect(false, drinkList);
  }

  useEffect(() => {
    const getDrinkStyles = async () => {
      const res = await apiWorker.get(ENDPOINTS.drinkStyles.main + `/${drinkTypeId}`);
      var data = res.data || {};
      const styles = listToObj(data.styles || []);
      setIsMixers(data.isMixers);
      setDrinkStyles(styles);
      setIsPageLoading(false);
    }

    const getTitle = () => {
      var type = state.drinkTypeSelections.find(t => t.id == drinkTypeId);
      if(type) {
        var parts = type.stylesTitle.split(/[\r\n]+/);
        setTitleParts(parts || []);
      }
    }
    
    if(state.drinkTypeSelections) {
      getTitle();
    }

    setIsPageLoading(true);
    if(state.drinkStyleSelections && state.drinkStyleSelections[drinkTypeId]) {
      setDrinkStyles(listToObj(state.drinkStyleSelections[drinkTypeId]));
      setIsMixers(state.mixerTypeId == drinkTypeId);
      setIsPageLoading(false);
    } else {
      getDrinkStyles();
    }
  }, [drinkTypeId]);

  return (
    <RefreshRedirect>
      <Render condition={!isPageLoading}>
        <ArrowButton displayText={"skip it"} handleClick={skipStyle} />
      </Render>
      <div className="card-content">        
        <h2 className="card-heading">
          <div className="styles-title">
            {titleParts.map((part, index) => 
              <div key={index} className="full-width">{part}</div>
            )}
          </div>
          <Render condition={!isPageLoading}>
            <div className="full-width centered sub-heading">
              Get More Specific
            </div>           
          </Render>
        </h2>
        <LoadingSection isLoading={isPageLoading}>
          <Row>
            {
              mapObjectEntries(drinkStyles, compareOrderables, ([id, style]) => 
                <Col xl={4} lg={4} med={4} sm={6} xs={12} key={id} className="drink-select-column">
                  <div className="column-wrapper">
                    <CheckableBox outlined={false} selected={style.isSelected} short={isMixers} handleClick={() => {setIsSelected(id)}}>
                      <div className="drink-style-inner-wrapper">
                          <Render condition={isMixers}>
                            <div className="drink-style-title">{style.name}</div>
                            <div className="style-description-body">{style.insightDescription}</div>
                          </Render>
                          <Render condition={!isMixers}>
                            <div className="drink-style-title">{style.name}</div>
                            <div className="style-description-title">Insight</div>
                            <div className="style-description-body">{style.insightDescription}</div>
                            <div className="style-description-title">Elements</div>
                            <div className="style-description-body">{style.elementsDescription}</div>
                            <div className="style-description-title">Great For</div>
                            <div className="style-description-body">{style.greatForDescription}</div>
                          </Render>
                      </div>
                    </CheckableBox>
                  </div>
                </Col>
              )
            }
          </Row>
          <div className="centered button-row">
            <LoadingButton disabled={isSubmitting} 
              className="btn-secondary" color="secondary" onClick={prevPage}>
                Go Back
            </LoadingButton>
            <LoadingButton loading={isSubmitting} disabled={isSubmitting} 
              className="btn-primary" color="primary" onClick={addDrinkStyles}>
                Next Step
            </LoadingButton>
          </div>
        </LoadingSection>
      </div>
    </RefreshRedirect>
  )
};

export default DrinkStyleSelection;