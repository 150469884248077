export const ROUTES = {
  eventSetup: "/EventSetup",
  drinkSelection: "/DrinkSelection",
  styleSelectionNoParams: "/DrinkStyles",
  styleSelection: "/DrinkStyles/:drinkTypeId",
  results: "/Results",
  getaquote: "/Quote",
  storelocator: "/StoreLocator",
  welcomeLocator: "/Welcome",
  discourseResponse: "/DiscourseResponse",
  myAccount: "/MyAccount",
  liquorCabinet: "/LiquorCabinet",
  liquorCabinetWelcome: "/LiquorCabinetWelcome",
  shoppingList: "/ShoppingList/:socialEventId",
  shoppingListNoParams: "/ShoppingList",
  signup: "/signup",
  login: "/login",
  logout: "/logout",
  signupSuccess: "/SignupSuccess",
  recoveryPassword: "/RecoveryPassword/:emailAddress/:requestId"
}

const api = "api";
const validate = "/validate";
const socialEvents = "/socialevents";
const saveSocialEvents = "/savesocialevent";
const socialEventsByUser = "/socialevents/user";
const socialEventOptionByUser = "/socialevents/options"
const userEventBySocialEvent = "/socialevents/userEvent"
const drinkTypes = "/drinktypes";
const drinkStyles = "/drinkstyles";
const drinkCalculator = "/drinkcalculator";
const drinkSponsorLogo = "/drinksponsorlogo";
const shoppingList = "/shoppinglist";
const shoppingListSendSms = "/sendsms";
const shoppingListSendEmail = "/sendemail";
const shoppingListAssociateList = "/AssociateList";
const savenotes = "/savenotes";
const getaquote = "/quote";
const drinkTypesSamples = "/drinktypesamples";
const discourse = "/discourse";
const discourseLogin = "/login";
const discourseGetToken = "/GetToken";
const discourseLogout = "/LogoutRemote";
const discourseSsoLogin = "/SsoLogin";
const discourseGetUrl = "/url";
const signup = "/signup";
const login = "/loginApp";
const user = "/user";
const editUser = "/edit";
const requestRecoveryPassword = "/requestChangePassword";
const recoveryPassword = "/changePassword";

export const ENDPOINTS = {
  socialEvents: {
    main: `${api}${socialEvents}`,
    validate: `${api}${socialEvents}${validate}`,
    byUser:`${api}${socialEventsByUser}`,
    options: `${api}${socialEventOptionByUser}`,
    userEvent: `${api}${userEventBySocialEvent}`,
    save: `${api}${socialEvents}${saveSocialEvents}`,

  },
  drinkTypes: {
    validate: `${api}${drinkTypes}${validate}` ,
    getAlcoholicDrinks: `${api}${drinkTypes}/true`,
    getNonAlcoholicDrinks: `${api}${drinkTypes}/false`,
  },
  drinkStyles: {
    main: `${api}${drinkStyles}`,
    validate: `${api}${drinkStyles}${validate}`,
  },
  drinkCalculator: {
    main: `${api}${drinkCalculator}`
  },
  drinkSponsorLogo: {
    main: `${api}${drinkSponsorLogo}`
  },
  shoppingList: {
    main: `${api}${shoppingList}`,
    saveNotes:`${api}${shoppingList}${savenotes}`,
    sendSms: `${api}${shoppingList}${shoppingListSendSms}`,
    sendEmail: `${api}${shoppingList}${shoppingListSendEmail}`,
    associateList: `${api}${shoppingList}${shoppingListAssociateList}`
  },
  getaquote:{
    post:`${api}${getaquote}`,
    getDeliveryTypes: `${api}${getaquote}`
  },
  drinkTypeSamples:{
    main: `${api}${drinkTypesSamples}`
  },
  discourse:{
    login: `${api}${discourse}${discourseLogin}`,
    getToken: `${api}${discourse}${discourseGetToken}`,
    Logout: `${api}${discourse}${discourseLogout}`,
    ssoLogin: `${api}${discourse}${discourseSsoLogin}`,
    getUrl: `${api}${discourse}${discourseGetUrl}`
  },
  signup:{
    main: `${api}${signup}`
  },
  login:{
    main: `${api}${login}`
  },
  user:{
    main: `${api}${user}`,
    requestRecoveryPassword: `${api}${user}${requestRecoveryPassword}`,
    recoveryPassword: `${api}${user}${recoveryPassword}`,
    edit: `${api}${user}${editUser}`
  }
}

export const URLS = {
  BLOG_MAIN: "http://blog.thedrinkcalculator.com/",
  CRAVENCO_SITE_MAIN: "https://www.cravenandco.com"
}

export const EXTERNAL_ENDPOINTS = {
  STOREPOINT: "https://cdn.storepoint.co/api/v1/js/15edfbe4e91254.js",
}

export const GOOGLE_ANALYTICS_ID = 'UA-134046988-3';
export const MAP_ID="15edfbe4e91254";

export const StoreLocatorSelectors ={
  button: ".storepoint-popup-directions",
  storePopup: ".storepoint-location-popup",
  address: ".street-address",
  phone: ".storepoint-popup-phone",
  email: ".storepoint-popup-email",
  zipcodeInput: ".mapboxgl-ctrl-geocoder--input",
  resultPanel: "#storepoint-panel"
}

export const SHOPPING_LIST_OPTION_KEYS = {
  newList: "NewList",
  ownList: "OwnList",
  pastList: "PastList"
}

export const INITIAL_STATE = {
  //used to track whether the state data still exists
  eventCreated: false,
  //initial event data
  event: {
    numberOfGuests: 0,
    numberOfHours: 0,
    thirstLevel: 2,
    isAlcoholicEvent: false,
    drinkTypeSelections: []
  },
  storeInfo: {
    name: "",
    address: "",
    phoneNumber: "",
    email: ""
  },
}

export const TIME_SETTINGS = {
  externalFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
}
