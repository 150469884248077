import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS, ROUTES } from 'Utilities/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';
import LoadingButton from 'Components/LoadingButton';

function DeleteEventButton({socialEventId, eventName, apiWorker, setAlert}){
	const [modal, setModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const toggle = () => setModal(!modal);
	let history = useHistory();

	const onDelete = ()=>{
		setButtonLoading(true);

		apiWorker.put(`${ENDPOINTS.socialEvents.main}/${socialEventId}`).then((resp)=>{
			history.push(ROUTES.liquorCabinet);
			toggle();
			setButtonLoading(false);
		}, (error)=>{
			setAlert({
				isOpen: true,
				message: 'Something was wrong!',
				color:"danger"
			});
			setButtonLoading(false);
			toggle();
		});
	}

	return(
		<div className="delete-event-button float-right">
			<FontAwesomeIcon icon={Icon.faTrashAlt} onClick={toggle} title="Delete event" className="btn-delete"/>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Confirmation</ModalHeader>
				<ModalBody>
					Are you sure to delete {eventName ? `"${eventName}"` : 'this event'}?
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>Cancel</Button>
					<LoadingButton color="danger" loading={buttonLoading} onClick={onDelete}>Delete</LoadingButton>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default DeleteEventButton;