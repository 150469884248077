import React, {useEffect, useState} from 'react';
import { Container } from 'reactstrap';
import blurredImage from '../../Assets/waves-min.jpg';
import hdImage from '../../Assets/waves-min2.jpg';
import medImage from '../../Assets/waves-min-med.jpg';
import DynamicBackground from 'Components/DynamicBackground';
import { URLS } from 'Utilities/Constants';


export default function Layout(props) {
  const [bgImageSrc, setBgImageSrc] = useState(blurredImage);

  useEffect(() => {
    const imgMed = new Image();
    imgMed.onload = () => {
      setBgImageSrc(medImage);
    }
    imgMed.src = medImage;

    const img = new Image();
    img.onload = () => {
      setBgImageSrc(hdImage);
    };
    img.src = hdImage;
  }, [])

  return(
    <DynamicBackground image={bgImageSrc}>
      <Container>
        <div className="main-card">
          {props.children}
          <div className="tagline" onClick={() => window.open(URLS.CRAVENCO_SITE_MAIN, "_blank")}>
            Powered by the event experts at Craven + Co.
          </div>
        </div>
      </Container>
    </DynamicBackground>
  );
};
