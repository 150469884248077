import { ENDPOINTS } from './Constants';
import axios from 'axios';
import {getToken} from 'Utilities/AuthService';

const eventKey = 'id_social_event_temp';

export const saveEvent = (id)=>{
	localStorage.setItem(eventKey, id);
}

export const getEvent = ()=>{
	return localStorage.getItem(eventKey);
}

export const persistSocialEvent = async (baseUrl) => {
	const token = getToken();

	const apiWorker = axios.create({
		baseURL: baseUrl,
		headers: {
			'Accept': '*/*',
			'Content-Type': 'application/json',
			'Authorization': token ? `Bearer ${token}` : null
		}
	});

	var socialEventId = localStorage.getItem(eventKey);
	if (!socialEventId) return;

	try{
		await apiWorker.post(ENDPOINTS.shoppingList.associateList, {socialEventId: socialEventId });
		localStorage.removeItem(eventKey);
	}
	catch (e){
		console.error(`Error trying to associate social event: ${socialEventId}. exception: ${e}`);
	}
}