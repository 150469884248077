import "./ErrorDisplay.scss";
import React from 'react';
import PropTypes from 'prop-types';

function ErrorDisplay({error, className, ...others}) {
  return (
    <div className={"centered error-text " + (className || "")} {...others}>
      {error}
    </div>
  );
}

ErrorDisplay.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string
}

export default ErrorDisplay;