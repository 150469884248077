import './DrinkCalculator.scss';
import React, {useState, useEffect} from 'react';
import {useApiWorker, useCalculatorContext} from 'Utilities/CustomHooks';
import { Row } from 'reactstrap';
import Render from 'Components/Render'
import LoadingButton from 'Components/LoadingButton';
import { ENDPOINTS } from 'Utilities/Constants';
import { listToObj, mapObjectEntries, compareOrderables} from 'Utilities/HelperFunctions';
import DrinkTypeSelectBox from 'Components/DrinkTypeSelectBox';

function StockBarUserGuide(props) {
  const {config, setIsModalShowing, ...other} = props;
  const [apiWorker] = useApiWorker(config.baseUrl);
  const [state] = useCalculatorContext();
  const [drinkTypes, setDrinkTypes] = useState(listToObj(state.drinkSamples || []));

  const setDrinkVolume = (key, volume) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], drinkVolume: volume}}));
  }

  const setPricePoint = (key, price) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], pricePoint: price}}));
  }

  const setIsSelected = (key) => {
    setDrinkTypes(prevTypes => ({...prevTypes, [key]: {...prevTypes[key], isSelected: !prevTypes[key].isSelected}}));
  }

  const handleSliderClick = (e, val) => {
  }
  const handleBoxClick = (e, id) => {
  }
  const setValues = (obj) =>{
    for(var key of Object.keys(obj)) {
      obj[key].isSelected = true;
    }
    setDrinkTypes(obj);
  }

  useEffect(() => {
    const getSampleTypes = async () => {
      const res = await apiWorker.get(ENDPOINTS.drinkTypeSamples.main);
      const objTypes = listToObj(res.data);
      setValues(objTypes);
    }
    if(state.drinkSamples === undefined || state.drinkSamples.length == 0) {
      getSampleTypes();
    } 
  }, [state.drinkSamples]);
 
  return (
    <div className="card-content stock-bar-user-guide-modal">
      <h2>how to stock your bar</h2>
      <div>
        Every bar is unique. To stock it properly, consider the experience you're creating for your guests.
      </div>
      <div className="user-guide-italic">
        For example, if this is your plan:
      </div>
      <div>
        You + your guests will sip on pre-dinner Vodka Gimlet Cocktails.
      </div>
      <div>
        Once everyone is seated for dinner, only wine will be served.
      </div>
      <div>
        Most of your guests love a luxe red wine, but few will want white wine (but they're not as picky).
      </div>
      <div className="user-guide-italic">
        Then these should be your selections:
      </div>
      <div className="centered">
        <Row>
            {
              mapObjectEntries(drinkTypes, compareOrderables, ([id, type]) => 
                <Render key={id}>
                  <DrinkTypeSelectBox id={id} unclickable={true}
                  handleClick={(e) => {handleBoxClick(e, id)}} handleSliderClick={handleSliderClick}
                  setPriceValue={(val) => {setPricePoint(id, val)}} setVolumeValue={(val) => {setDrinkVolume(id, val)}} {...type} />              
                </Render>
              )
            }
          </Row>
      </div>
      <div className="button-row centered">
        <LoadingButton className="btn-secondary" color="secondary"
          onClick={() => setIsModalShowing(false)}>
            Now I'm ready
        </LoadingButton>
      </div>
    </div>
  )
};

export default StockBarUserGuide;