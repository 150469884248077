import 'App.scss';
import React from 'react';
import { Switch } from 'react-router-dom';
import LayoutRoute from 'Context/ContextRoute';
import Layout from 'Features/Home/Layout';
import EventSetup from 'Features/DrinkCalculator/EventSetup';
import DrinkTypeSelection from 'Features/DrinkCalculator/DrinkTypeSelection';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {ROUTES} from './Utilities/Constants';
import DrinkStyleSelection from 'Features/DrinkCalculator/DrinkStyleSelection';
import ViewResults from 'Features/DrinkCalculator/ViewResults';
import GlobalLayout from 'Features/Home/GlobalLayout';
import StoreLocator from 'Features/StoreLocator/StoreLocator';
import MyAccount from 'Features/User/MyAccount';
import RecoveryPassword from 'Features/User/RecoveryPassword';
import DiscourseResponse from 'Components/DiscourseResponse';
import LiquorCabinet from 'Features/LiquorCabinet/LiquorCabinet';
import LiquorCabinetWelcome from 'Features/LiquorCabinet/LiquorCabinetWelcome';
import ShoppingList from 'Features/LiquorCabinet/ShoppingList';
import SignupSuccess from 'Features/User/SignupSuccess';
import Signup from 'Features/User/SignUp';
import LoginPage from 'Features/User/LoginPage';
import LogOut from 'Features/User/LogOut';

library.add(fab)

export default function App(props) {
  return (
    <GlobalLayout {...props}>
      <Switch>
        <LayoutRoute exact path='/' layout={Layout} component={EventSetup} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.eventSetup} layout={Layout} component={EventSetup} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.drinkSelection} layout={Layout} component={DrinkTypeSelection} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.styleSelection} layout={Layout} component={DrinkStyleSelection} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.results} layout={Layout} component={ViewResults} componentProps={{...props}} />
        <LayoutRoute path={ROUTES.storelocator} component={StoreLocator} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.discourseResponse} component={DiscourseResponse} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.myAccount} layout={Layout} component={MyAccount} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.liquorCabinet} component={LiquorCabinet} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.liquorCabinetWelcome} layout={Layout} component={LiquorCabinetWelcome} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.shoppingList} component={ShoppingList} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.signupSuccess} layout={Layout} component={SignupSuccess} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.recoveryPassword} layout={Layout} component={RecoveryPassword} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.signup} layout={Layout} component={Signup} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.login} layout={Layout} component={LoginPage} componentProps={{...props}} />
        <LayoutRoute exact path={ROUTES.logout} layout={Layout} component={LogOut} componentProps={{...props}} />
        {/*You would add your error route here because /* is a catch all route */}
        <LayoutRoute path='/*' layout={Layout} component={EventSetup} componentProps={{...props}}/>

      </Switch>
    </GlobalLayout>
  )
};
