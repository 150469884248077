import React, {useState} from 'react'
import {Button} from 'reactstrap';
import ChangePassword from './ChangePassword';

function ChangePasswordButton(props)
{
	const [modal , setModal] = useState(false);
	const toggle = () => setModal(!modal);
	
	return(
		<div>
			<Button onClick={toggle} color="primary" className="full-width">Change Password</Button>
			<ChangePassword isForgettingPassword={false} {...props} modalShow={modal} modalToggle={toggle}/>
		</div>
	);
}

export default ChangePasswordButton;