import "../DrinkCalculator/DrinkCalculator.scss";
import "./RowDrink.scss";
import React, {useState} from 'react';
import ReactStars from 'react-stars';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';
import Textarea from 'Components/TextareaBlock';
import noProductImage from 'Assets/droplet-blue.png';
import Render from 'Components/Render';


function ResultsRow(props) {
  const {recommendation, isLastEntry, onChangeRating, onChangeNotes, ...others} = props;
  const [notes, setNotes] = useState(recommendation.notes);
  const [rating, setRating] = useState(recommendation.rating);

  const onChangeRatingLocal = (value)=> {
    onChangeRating({
      id: recommendation.id,
      rating: value
    });
    setRating(value);
  }

  const onChangeNotesLocal = (value)=> {
    onChangeRating({
      id: recommendation.id,
      notes: value
    });
    setNotes(value);
  }

  const [notesModal, setNotesModal] = useState(false);
  const toggle = () => setNotesModal(!notesModal);
  const onSaveNotes = ()=>{
    onChangeNotes({
      id: recommendation.id,
      notes: notes
    });
    toggle();
  }
  
  return (
    <div className={"recommendation-row " + (isLastEntry ? "last-row" : "")}>
      <Row>
        <Col xl={3} lg={2} md={3} sm={3} xs={12} className="drink-select-column">
          <div className="column-wrapper centered">
            <div className="sponsor-image-wrapper">
              <Render condition={recommendation.logoImgSrc}>
                <img className="sponsor-image" src={recommendation.logoImgSrc} />  
              </Render>
              <Render condition={!recommendation.logoImgSrc}>
                <img className="no-product" src={noProductImage}/>
              </Render>
            </div>
          </div>
        </Col>
        <Col xl={1} lg={2} md={2} sm={2} xs={6} className="recommendation-column">
          <div className="column-wrapper centered">
            <div className="recommendation-amount">
              {recommendation.amountFullText}
            </div>
          </div>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6} className="d-block d-sm-none">
           <ReactStars count={5} size={24} color2={'#ffd700'} value={rating} onChange={onChangeRatingLocal}/>
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} xs={6} className="recommendation-name-column">
          <div className="column-wrapper centered flex-column recommendation-name">
            <div className="style-name full-width">{recommendation.name}</div>
            <div className="sponsor-product-name full-width">{recommendation.sponsorProductName}</div>
          </div>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} className="d-none d-sm-block rating-column" >
           <div className="stars">
              <ReactStars count={5} size={24} color1={'#dedede'} color2={'#7f7f7f'} value={rating} onChange={onChangeRatingLocal}/>
           </div>
        </Col>
        <Col xl={3} lg={3} md={2} sm={2} xs={6} className="edit-drink-column">
          <div onClick={toggle} className="btn-edit" color="none">
            <FontAwesomeIcon icon={Icon.faEdit}/><span className="ml-1">{notes || 'Add notes..'}</span>
          </div>
        </Col>
      </Row>
      <Modal isOpen={notesModal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Edit Notes</ModalHeader>
          <ModalBody>
            <Textarea value={notes} setValue={setNotes} />
          </ModalBody>
          <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onSaveNotes}>Accept</Button>
          </ModalFooter>
      </Modal>
    </div>
  )
}

export default ResultsRow;