import './DrinkCalculator.scss';
import './SendShoppingList.scss';
import React, {useState, useEffect} from 'react';
import { Row, Col, Alert } from 'reactstrap';
import LoadingButton from 'Components/LoadingButton';
import InputBlock from 'Components/InputBlock.js';
import { useCalculatorContext } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import { getProfile, loggedIn } from 'Utilities/AuthService';
import Render from "Components/Render";
import { useApiWorker } from 'Utilities/CustomHooks';
import SendButton from './SendButton';
import { saveEvent } from 'Utilities/LocalSocialEventService';
import ReminderLoginLabel from './ReminderLoginLabel';
import {ROUTES} from 'Utilities/Constants';

function SendShoppingListContent(props) {
  const {config, setIsModalShowing, setSuccessModalShowing, sentSocialEventId, ...other} = props;
  const [state, sendUpdate] = useCalculatorContext();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isSubmittingSms, setIsSubmittingSms] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({username:""});
  const [apiWorker] = useApiWorker(config.baseUrl);
  const [socialEventId, setSocialEventId ] = useState(state.userEvent && state.userEvent.socialEvent ? state.userEvent.socialEvent.id : sentSocialEventId);
  const [mailSent, setMailSent] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [userEvent, setUserEvent] = useState([]);

  useEffect(()=>{
    const getUserInfo = async () => {
      var resp = await apiWorker.get(`${ENDPOINTS.user.main}/${getProfile().nameid}`);
      setUser(resp.data);
      setEmailAddress(resp.data.emailAddress);
      setPhoneNumber(parseInt(resp.data.phoneNumber)); 
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    const getUserEvent = async () => {
      var resp = await apiWorker.get(`${ENDPOINTS.socialEvents.userEvent}/${socialEventId}`);
      const data = resp.data || {};
      setUserEvent(data);
    }
    
    if(Object.keys(userEvent).length === 0) {
      if(state.userEvent){
        setUserEvent(state.userEvent);
      }
      else{
        getUserEvent();
      }
  }
  }, [userEvent])

  const handleSuccess = async (id)=>{
    setSocialEventId(id);
    saveEvent(id);
    if(state.userEvent){
      state.userEvent.socialEvent.id = id;
      sendUpdate(state);
    }
  }

  const handleError = async (message)=>{
    setAlert({
      isOpen: true,
      color: 'danger',
      message: message
    });
  }

  const [alert, setAlert] = useState({
    isOpen: false,
    color: '',
    message: ''
  });

  const alertToggle = ()=>{setAlert({isOpen:false})}

  return (
    <div className="send-shopping-list-modal">
      <h1 className="card-heading">order up!
        <div className="full-width centered sub-heading">
          We can send your list via text or email, or both!
        </div>
        <ReminderLoginLabel {...props} redirectTo={ROUTES.results}/>
      </h1>
      <Alert {...alert} toggle={alertToggle}>
        {alert.message}
      </Alert>
      <Row className="full-width">
        <Col md={9} className="full-width">
          <InputBlock value={phoneNumber} setValue={setPhoneNumber} name="phone" autoComplete="tel" id="phone-number-input"
            onValueChange={(values) => setPhoneNumber(values.value)} format="(###) ###-####" isNumberFormat={true} useUnformattedValue={true} placeholder="phone number"/>
        </Col>
        <Col md={3}>
          <SendButton displayName="Send SMS"
                      userEvent={userEvent} 
                      socialEventId={socialEventId} 
                      handleSuccess={handleSuccess} 
                      handleError={handleError} 
                      baseUrl={config.baseUrl} 
                      postUrl={ENDPOINTS.shoppingList.sendSms} 
                      value={phoneNumber} 
                      valueName="phoneNumber"
                      username={user.username}/>
        </Col>
      </Row>
      <Row className="full-width">
        <Col md={9} className="full-width">
          <InputBlock value={emailAddress} setValue={setEmailAddress} name="email" autoComplete="email" id="email-input" placeholder="email@email.com"/>
        </Col>
        <Col md={3}>
          <SendButton displayName="Send Email"
                      userEvent={userEvent} 
                      socialEventId={socialEventId} 
                      handleSuccess={handleSuccess} 
                      handleError={handleError} 
                      baseUrl={config.baseUrl} 
                      postUrl={ENDPOINTS.shoppingList.sendEmail} 
                      value={emailAddress} 
                      valueName="emailAddress"
                      username={user.username}/>
        </Col>
      </Row>
      <div className="button-row centered">
        <LoadingButton disabled={isSubmittingSms || isSubmittingEmail} 
          className="btn-secondary" color="secondary" onClick={() => setIsModalShowing(false)}>
            Go back
        </LoadingButton>
      </div>
    </div>
  )
};

export default SendShoppingListContent;