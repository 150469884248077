import React from 'react';
import NavMenu from 'Features/Home/NavMenu';
import Footer from './Footer';
import Store from 'Context/Store';

export default function GlobalLayout(props) {
  return(
    <Store>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <NavMenu {...props}/>
      {props.children}
      <Footer />     
    </Store>
  );
};
