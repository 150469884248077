import './CustomIcons.scss';
import React from 'react';

export const Plus = ({height, ...other}) => {
  return (
    <svg viewBox="0 0 100 100" height={height} className="incrementor-icon">
      <line x1="50" y1="0" x2="50" y2="100" />
      <line y1="50" x1="0" y2="50" x2="100" />
    </svg>
  )
}

export const Minus = ({height, ...other}) => {
  return (
    <svg viewBox="0 0 100 100" height={height} className="incrementor-icon">
      <line y1="50" x1="0" y2="50" x2="100" />
    </svg>
  )
}

export const SliderBall = ({height, ...other}) => {
  return (
    <div className="slider-ball-wrapper" draggable>
      <svg viewBox="0 0 100 100" height={height} className="slider-ball">
        <circle cx="50" cy="50" r="50"/>
      </svg>
    </div>
  )
}

export const Check = ({width, ...other}) => {
  return (
    <svg viewBox="0 0 52 52" width={width} className="checkmark">
      <path  d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
  )  
}

export const Arrow = ({width, height, ...other}) => {
  return (
  <svg className="arrow-icon" width={width} height={height}>
    <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/>
    </svg>
  )
}

export const SliderDroplet = ({height, ...other}) => {
  return(
    <div className="slider-ball-wrapper" draggable>
      <svg className="slider-droplet" viewBox="0 0 800 700" height={height}>
        <path d="M 400 0 Q 300 0 200 100 Q 100 200 150 350 L 250 500 L 400 700 L 550 500 L 650 350 Q 700 200 600 100 Q 500 0 400 0 Z" />
      </svg>
    </div>
  ) 
}