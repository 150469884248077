import * as React from 'react';
import ReactDOM from 'react-dom';
import { ENDPOINTS } from 'Utilities/Constants';
import { useApiWorker } from 'Utilities/CustomHooks';

function DiscourseResponse (props) {
	ReactDOM.render(<div>Redirecting...</div>, document.getElementById('root'));
	var params = new URLSearchParams(window.location.search);
	var sso = params.get('sso');
	var sig = params.get('sig');

	var { config } = props;
	var [apiWorker] = useApiWorker(config.baseUrl);
	apiWorker.get(`${ENDPOINTS.discourse.getToken}?sso=${sso}&sig=${sig}`).then((res)=>{
		window.opener.postMessage({ sso: sso, sig: sig, response: res.data  }, document.location.origin);
		window.close();
	});
	return (<div></div>);
}

export default DiscourseResponse;