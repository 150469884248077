export const listToObj = (list, key) => {
  if(key === undefined) key = "id";
  var dict = list.reduce((obj, item) => {
    const {...other} = item; 
    return (obj[item[key]] = other, obj);
  }, {});
  return dict;
}

export const objToList = (obj) => {
  var list = Object.keys(obj).map((key) => {
    return {id: key, ...obj[key]}
  });
  return list;
}

export const getClosest = (elem, selector) => {
	// Element.matches() polyfill
	if (!Element.prototype.matches) {
	    Element.prototype.matches =
	        Element.prototype.matchesSelector ||
	        Element.prototype.mozMatchesSelector ||
	        Element.prototype.msMatchesSelector ||
	        Element.prototype.oMatchesSelector ||
	        Element.prototype.webkitMatchesSelector ||
	        function(s) {
	            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
	                i = matches.length;
	            while (--i >= 0 && matches.item(i) !== this) {}
	            return i > -1;
	        };
	}

	// Get the closest matching element
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}
	return null;
};

export const mapObjectEntries = (obj, compare, mapping) => {
  var entries = Object.entries(obj).sort(([idA, valA], [idB, valB]) => compare(valA, valB));
  return entries.map(mapping);
}

export const compareOrderables = (firstVal, secondVal) => {
	if(firstVal.order === secondVal.order) {
		return firstVal.name.localeCompare(secondVal.name);
	}
	return firstVal.order - secondVal.order;
}

export const attachEventListener = (selector, eventListener, callback) => {
	const handler = (e) => {
		let selectorItems = document.querySelectorAll(selector);
		let paths = e.composedPath();
		
		if(paths !== undefined){
			for(let item of paths){
				for(let selectorItem of selectorItems){
					if(item === selectorItem){
						if(eventListener === 'click'){
							e.preventDefault();
						}
						callback(item);
						break;
					}
				}
			}
		}
	}

	document.addEventListener(eventListener, handler);
	return handler;
}