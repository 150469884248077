import React, {useState, useEffect} from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Footer(props) {
  const [year, setYear] = useState(2020);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, [year]);


  return (
    <footer>
      <div className="main-footer">
        <div className="social-media-links">
          <a href="https://www.instagram.com/cravenandco/" target="_blank" rel="noopener" className="instagram-icon">          
            <FontAwesomeIcon icon={['fab', 'instagram']} className="social-media-icon"/>
          </a>
          <a href="https://www.pinterest.com/cravenandco/" target="_blank" rel="noopener">          
            <FontAwesomeIcon icon={['fab', 'pinterest']} className="social-media-icon"/>
          </a>
          <a href="https://www.facebook.com/cravenandcompany/" target="_blank" rel="noopener">          
            <FontAwesomeIcon icon={['fab', 'facebook-f']} className="social-media-icon"/>
          </a>
        </div>
        <div className="footer-title first">Reach out anytime. We'd love to hear from you.</div>
        <div className="contact-description">Our experts are happy to review your bar list with you and answer any questions to help you get the perfect shopping list.</div>
        <div className="contact-description">cheers@thedrinkcalculator.com</div>
        <div className="contact-description">512.806.0707</div>
        <div className="footer-title second">Event Expertise on tap</div>
        <div className="contact-description">Sometimes, you need even more help than dialing in your bar list. 
          Our 20 years of event planning experience also make us the perfect partner for any and all aspects of your party. 
          Craven + Co. By The Hour empowers our clients to tap into our event expertise, creativity, and contacts - one hour at a time.
        </div>
        <div className="footer-link-wrapper">
          <ul className="footer-link plus">
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
               Rental list curation
              </a>
           </li>
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
                Menu Selection
              </a>
           </li>
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
               Activity Suggestions
              </a>
           </li>
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
                Vendor Recommendations
              </a>
           </li>
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
               Creative Solutions
              </a>
           </li>
           <li>
              <a href="https://cravenandco.com/bythehour" target="_blank" rel="noopener noreferrer">
               Anything else you're dreaming up
              </a>
           </li>
          </ul>
        </div>
        <div className="copyright small">
          &copy; {year} Craven + Co.
        </div>
      </div>
    </footer>
  );
}
