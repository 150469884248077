import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_ID } from 'Utilities/Constants';
import Render from './Render';

const defaultConfig = {
  baseUrl: "https://localhost:44378/"
}

export default function ConfigLoader (props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [config, setConfig] = useState(defaultConfig);
  const [logginChanging, setLogginChanging] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      const res = await axios.get("/apiConfig.json").catch((error) => {console.log(error);});
      const {data} = res || {};
      setConfig({...defaultConfig, logginChanging, setLogginChanging,...data});
      setIsLoaded(true);
    }
    getConfig();
  }, [isLoaded]);

  useEffect(()=>{
    setLogginChanging(false);
    setIsLoaded(false);
  }, [logginChanging]);

  return (
    <Render condition={isLoaded}>
      {props.ready(config)}
    </Render>
  );
}