import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './LiquorCabinet.scss'
import CravenLine from '../../Assets/cravenlinestyle.svg';
import Render from "Components/Render";
import { loggedIn } from 'Utilities/AuthService';
import LiquorCabinetWelcome from './LiquorCabinetWelcome';
import SocialEventList from './SocialEventList';
import { ROUTES } from 'Utilities/Constants';
import { Row, Col } from 'reactstrap';

function LiquorCabinet(props){
    let history = useHistory();
	const createNew = (e)=>{
		history.push(ROUTES.eventSetup);
	}
	const clickEvent = (e)=>{
		history.push(`${ROUTES.shoppingListNoParams}/${e.target.id}`);
	}

	useEffect(()=>{
		if (!loggedIn()) {
			history.push(ROUTES.liquorCabinetWelcome);
		}
	}, []);

	return(
		<div className="liquor-cabinet">
			<Render condition={loggedIn()}>
				<Row>
					<Col xl={4} lg={12} md={12} sm={12}>
						<Col xs={{size: 8, offset: 2}} className="lc-title">
							<h2>the liquor<br/>cabinet</h2>
						</Col>
						<Row>
							<Col xl={5} lg={2} md={2} sm={3} className="d-none d-sm-block">
								<img src={CravenLine} className="mt-4"/>
							</Col>
							<Col xl={{size: 7, offset: 0}} lg={{size: 3, offset: 0}}  md={{size: 3, offset: 1}} 
								sm={{size: 3, offset: 0}} xs={{size: 6, offset: 3}}  className="lc-description">
								<p>		
									Click on a square to access a past shopping list.
									Once inside, use advance features to fine tune your bar hosting skills.
								</p>
							</Col>
							<Col md={5} sm={5} className="d-block d-sm-none craven-line-vertical">
								<img src={CravenLine} className="mt-4"/>
							</Col>
						</Row>
					</Col>
					<Col xl={8} lg={12} md={12} sm={12}  className="lc-social-events">
						<SocialEventList {...props} createNew={createNew} clickEvent={clickEvent}/>
					</Col>
				</Row>
			</Render>
        </div>
	);
}

export default LiquorCabinet;