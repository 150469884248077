import PropTypes from 'prop-types';

const Render = ({children, condition=true}) => {
  return (
    condition 
      ? children
      : null
  )
};

Render.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Render;