import "./DrinkCalculator.scss";
import React from 'react';
import { Row, Col } from 'reactstrap';
import NewTabLink from 'Components/NewTabLink';
import { compareOrderables } from "Utilities/HelperFunctions";

function BlogLinksSection(props) {
  const {links, title, ...others} = props;

  return (
    <div className="results-section" {...others}>
      <h2>{title}</h2>
      <Row className="blog-links-row">
        {
          links.sort(compareOrderables).map((link, index) => 
            <Col xs="12" sm="6" md="4" lg="4" xl="4" className="blog-link-col centered" key={index}>
              <NewTabLink className="blog-link__wrapper flex-column" url={link.link}>
                <div className="blog-link__hover-bg full-width" />
                <div className="blog-link__img-wrapper full-width">
                  <img className="blog-link__img full-size" alt="Blog Preview" src={link.previewImgSrc} />
                </div>
                <div className="blog-link__title full-width">{link.name}</div>
              </NewTabLink>
            </Col>
          )
        }
      </Row>
    </div>
  )
}

export default BlogLinksSection;