import React, {useState, useEffect} from 'react';
import Login from '../User/Login';
import { ROUTES } from 'Utilities/Constants';
import { loggedIn } from 'Utilities/AuthService';
import { useHistory } from 'react-router-dom';
import './LiquorCabinetWelcome.scss';

function LiquorCabinetWelcome(props) {
    const {config, ...others} = props;
    const [isSignUpShowing, setSignUpShowing] = useState(false);
    const {logginChanging, setLogginChanging} = config;
    let history = useHistory();

    useEffect(()=>{
        if (loggedIn()) {
            history.push(ROUTES.liquorCabinet);
        }
    }, [logginChanging])

    return (
        <div className="liquor-cabinet-welcome">
            <Login title="The Liquor Cabinet" subtitle="Connect your cabinet to access the full features of the good stuff"
                {...props} config={config} setIsModalShowing={setSignUpShowing} redirectTo={ROUTES.liquorCabinet}/>
        </div>
    )
}

export default LiquorCabinetWelcome;
