import './MyAccount.scss';
import React, {useState, useEffect} from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';
import { getProfile, loggedIn, logout } from 'Utilities/AuthService';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import LoadingButton from 'Components/LoadingButton';
import ChangePasswordButton from './ChangePasswordButton';
import EditUserInfoTable from './EditUserInfoTable';

function MyAccount (props) {
	if (!loggedIn()) {
		window.location = window.location.origin;
	}

  	const { config } = props;
	const [apiWorker] = useApiWorker(config.baseUrl);

	const [userId, setUserId] = useState();
	const [userName, setUserName] = useState();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState();
	const [phoneNumber, setPhoneNumber] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [newsletterSubs, setNewsletterSubs] = useState(false);
	const userInfo = {userId, setUserId, userName, setUserName, firstName, setFirstName, 
			lastName, setLastName, email, setEmail, phoneNumber, setPhoneNumber, zipCode, 
			setZipCode, newsletterSubs, setNewsletterSubs};

	const [loadingSaveUserInfo, setLoadingSaveUserInfo] = useState(false);

	const getUserInfo = async () => {
		var resp = await apiWorker.get(`${ENDPOINTS.user.main}/${getProfile().nameid}`);
		setUserId(resp.data.id);
		setUserName(resp.data.username);
		setFirstName(resp.data.firstName);
		setLastName(resp.data.lastName);
		setEmail(resp.data.emailAddress);
		setPhoneNumber(resp.data.phoneNumber);
		setZipCode(resp.data.zipCode);
		setNewsletterSubs(resp.data.newsletterSubs);
	}

	useEffect(()=>{
		getUserInfo();
	}, []);

	const LogOut = async () => {
		await logout(config);
		window.location = window.location.origin;
	}

	const handleSuccessChangePassword = ()=>{
		setAlert({
			isOpen: true,
			color: 'success',
			message: `A confirmation mail was sent to ${email}. Go to your inbox to continue the process.`
		});
	}
	const handleErrorChangePassword = (resp)=>{
		setAlert({
			isOpen: true,
			color: 'danger',
			message: resp
		});
	}
	const handleSuccessEditInfo = ()=>{
		setAlert({
			isOpen: true,
			color: 'success',
			message: `Your user information was changed successfully.`
		});
		getUserInfo();
		setLoadingSaveUserInfo(false);
	}

    const [alert, setAlert] = useState({
    	isOpen: false,
    	color: '',
    	message: ''
    });

    const alertToggle = ()=>{setAlert({isOpen:false})}

    const onSaveUserChanges = async () => {
    	setLoadingSaveUserInfo(true);
		try{
			const data = {
				id: userId,
				firstName: firstName,
				lastName: lastName,
				phoneNumber: phoneNumber,
				zipCode: zipCode
			};
		 	handleSuccessEditInfo(true);
		}catch(e){
			if (e.response && e.response.data && e.response.data.errors) {
	    		var errorMessage = [];
				const errorKeys = Object.keys(e.response.data.errors);
				errorKeys.map((key)=>{
					e.response.data.errors[key].map((msg)=>{
						errorMessage.push(msg);
					});
				});
				alertToggle();
				setAlert({
					isOpen: true,
					color: 'danger',
					message: errorMessage.join('. ')
				});
			}else{
				alertToggle();
				setAlert({
					isOpen: true,
					color: 'danger',
					message: ["Something was wrong!"]
				});
			}
			setLoadingSaveUserInfo(false);
		}
    }

	return(
		<Row className='my-account'> 
			<Alert {...alert} toggle={alertToggle} className="alert">
				{alert.message}
			</Alert>
			<EditUserInfoTable {...userInfo}/>
			<Col md={4} xs={12}>
				<ChangePasswordButton emailAddress={email} {...props} handleSuccess={handleSuccessChangePassword} handleError={handleErrorChangePassword}/>
			</Col>	
			<Col md={4} xs={12}>
				<LoadingButton loading={loadingSaveUserInfo} className="btn-primary btn-lightgreen full-width" color="primary" onClick={onSaveUserChanges}>Save Changes</LoadingButton>
			</Col>	
			<Col md={4} xs={12}>
				<button className="btn btn-info full-width" onClick={LogOut}><FontAwesomeIcon icon={Icon.faSignOutAlt}/><span className="ml-1">Log Out</span></button>
			</Col>			
		</Row>
	);
}

export default MyAccount;