import './DiscourseLoginButton.scss';
import * as React from 'react';
import { ENDPOINTS } from 'Utilities/Constants';
import discourseIcon from '../Assets/discourse_icon.svg';

const width = 800, high = 600;

function DiscourseLoginButton (props) {
	const onClick = () => {
		var { baseUrl } = props;
		var url = `${baseUrl}${ENDPOINTS.discourse.login}`;
		var x = (window.screen.width/2)-(width/2); 
		var y = (window.screen.height/2)-(high/2); 
		var popup = window.open(url, url,
			`width=${width},height=${high},
			menubar=0,toolbar=0,directories=0,
			scrollbars=no,resizable=no,
			left=${x},top=${y}`);

		window.addEventListener("message", responseListenerEvent, false);

		function responseListenerEvent(event) {		
			if (props.handleSuccess) {
				props.handleSuccess({data: event.data.response });
			}
			window.removeEventListener("message", responseListenerEvent);
		}
	}

	return(
		<div>
			<button type="button" className="btn btn-discourse discourse-label" onClick={onClick}>
				<img src={discourseIcon} className="logo-discourse"/>
			</button>
		</div>
	);
}

export default DiscourseLoginButton;