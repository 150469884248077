import React, {useState} from 'react'; 
import { getProfile, loggedIn } from 'Utilities/AuthService';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'Utilities/Constants';

function SignupSuccess(props){
	const {collapse} = props;
	if (!loggedIn()) {
		window.location = window.location.origin;
	}

  	let history = useHistory();
	var user = getProfile();
	const [userName] = useState(user.nameid);

	const redirectToMyAccount = async () => {
  	    history.push(ROUTES.myAccount);
  	    if (collapse) {collapse()}
	}

	return(
		<div className="center">
			<h2>You're in!</h2>
			<h4>Welcome {userName} to Craven+Co community</h4>
			<span>
				Now you can access the full features like: 
				access past shopping lists, rate the goods you tried, make notes like a pro, duplicate past shopping lists and more.
			</span>
			<div className="button-row centered">
				<button className={"btn btn-warning center"} onClick={redirectToMyAccount} >View my account profile</button>
			</div>
		</div>
	);
}

export default SignupSuccess;