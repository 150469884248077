import React, {useState, useEffect} from 'react';
import Login from './Login';
import { loggedIn } from 'Utilities/AuthService';
import LoadingSection  from 'Components/LoadingSection';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import { autovalidateSso, cancelAutoValidateSso } from 'Utilities/DiscourseService';
import './LoginPage.scss'

function LoginPage(props){
	const {config} = props;
	var [apiWorker] = useApiWorker(config.baseUrl);

	var params = new URLSearchParams(window.location.search);
	const [sso] = useState(params.get('sso'));
	const [sig] = useState(params.get('sig'));

	const [loadingMessage, setLoadingMessage] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const  onBeforeLogin = (data)=>{
		data.sso = sso;
		data.sig = sig;
		setIsLoading(true);
		cancelAutoValidateSso();
	}

	const onHandleSuccess = (res)=>{
		if(res.data.redirectTo)
			window.location = res.data.redirectTo;
	}

	const onHandleError = ()=>{
		setIsLoading(false);
	}

	useEffect(()=>{
		const validateSso = async ()=>{
			if(sso && sig && loggedIn() && autovalidateSso()){
				setLoadingMessage("Loading Party Animals Community");
				setIsLoading(true);
				var resp = await apiWorker.post(ENDPOINTS.discourse.ssoLogin, {sso: sso, sig:sig});
				if(resp.data)
					window.location = resp.data;
			} 
		};
		validateSso();
	}, []);

	return(
		<div className="login-page">
			<LoadingSection isLoading={isLoading} loadingText={loadingMessage}>
				<Login {...props}  onBeforeLogin={ onBeforeLogin} onHandleSuccess={onHandleSuccess} noRedirect={true} onHandleError={onHandleError}/>
			</LoadingSection>	
		</div>
	);
}

export default LoginPage;
