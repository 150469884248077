import React, {useState, useEffect}  from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import ModalCloseButton from 'Components/ModalCloseButton';
import ActionTypes from 'Context/ActionTypes';
import {useCalculatorContext} from 'Utilities/CustomHooks';
import {attachEventListener} from 'Utilities/HelperFunctions';
import {StoreLocatorSelectors, ROUTES} from 'Utilities/Constants';
import QuoteForm from '../GetAQuote/QuoteForm';
import QuoteFormSuccessModal from '../GetAQuote/QuoteFormSuccessModal';

function StoreLocatorModalWrapper(props){
    const {config,...other} = props
    const [modalOpen, setModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [state, sendUpdate] = useCalculatorContext();
    const [drinkRecommendations, setDrinkRecommendations] = useState([]);
    const [otherEssentials, setOtherEssentials] = useState([]);
    let history = useHistory();

    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleSuccessQuoteModal = () => setSuccessModalOpen(!successModalOpen);
    
    const redirectToEventSetup = () =>{
        history.push(ROUTES.eventSetup);
    }

    const setStoreInfo = (store) => {
        const storeName = store.firstChild;
        const address = store.querySelector(StoreLocatorSelectors.address);
        const phone = store.querySelector(StoreLocatorSelectors.phone);
        const email = store.querySelector(StoreLocatorSelectors.email);
        
        const storeInfo ={
            name: storeName ? storeName.textContent : "",
            address: address ? address.textContent : "",
            phoneNumber: phone ? phone.textContent : "",
            email: email ? email.textContent : "",
        }

        sendUpdate(ActionTypes.setStoreInformation, storeInfo);    
    }

    const setCreatedEventData = () => {
        if(state.userEvent && state.userEvent.essentialItems && state.userEvent.recommendations){
            setDrinkRecommendations(state.userEvent.recommendations || []);
            setOtherEssentials(state.userEvent.essentialItems || []);
        }
        setModalOpen(true);
    }

    useEffect(() => {
        // add event listener to store locator 'get a quote' button rendered by storepoint container 
        // save the selected store info in context and open the get a quote modal
        const handleClick = (element) => {
            const storePopup = element.closest(StoreLocatorSelectors.storePopup);
            if(storePopup){
                setStoreInfo(storePopup);
            }
            toggleModal();
        }

        // if a user selected create event from the 'get a quote' in store locator, 
        // it will be redirected to store locator with the modal opened and the drink details that were just calculated from the state
        if(state.storeInfo.name.length > 0 && state.userEvent){
            setCreatedEventData();
        }

        const eventListener = attachEventListener(StoreLocatorSelectors.button, 'click', handleClick);
        
        return function cleanupListener() {
            document.removeEventListener("click", eventListener)
        }
    }, [])

    return(
        <div>
            <Modal isOpen={modalOpen} className="store-locator-modal-wrapper" toggle={toggleModal} backdrop={true} backdropClassName="primary-backdrop">
                <ModalBody>
                <ModalCloseButton toggle={toggleModal} set/>
                    <QuoteForm config={config} setIsModalShowing={setModalOpen} setSuccessQuoteModalShowing={setSuccessModalOpen} recommendations={drinkRecommendations} essentials={otherEssentials}/>        
                </ModalBody>
            </Modal>
        
        
            <Modal isOpen={successModalOpen} className="store-locator-modal-wrapper" toggle={toggleSuccessQuoteModal} backdrop={true} backdropClassName="primary-backdrop">
                <QuoteFormSuccessModal toggle={toggleSuccessQuoteModal}/>
            </Modal>
        </div>
    )
}

export default StoreLocatorModalWrapper;