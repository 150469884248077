import React, {createContext, useReducer} from "react";
import Reducer from './Reducer';
import { INITIAL_STATE } from "Utilities/Constants";

const initialState =  {
  ...INITIAL_STATE
  /*--- Contains ID of mixer type
    mixerTypeId
  */
  /*--- Contains all selected drink types once drink type selection is complete
    drinkTypeSelections: [
      {id, pricePoint, drinkVolume},
      ...
    ]
  */
  /*--- Contains all selected drink styles, listed by drink type id
    drinkStyleSelections: {
      drinkTypeId: {
        drinkTypeId, 
        styleList: [
          {id}
        ]
      }
    }
  */
};

const Store = ({children}) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
};

export const Context = createContext(initialState);
export default Store;