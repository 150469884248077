import './LoadingSection.scss';
import React, { Fragment } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from 'prop-types';

function LoadingSection({isLoading, loadingText, children, ...others}) {
  return (
    <Fragment>
      {
        isLoading &&
        <div className="loading-wrapper" {...others}>
          <div className="full-width centered">
            <PulseLoader color={"#003057"} loading={isLoading} size={12} margin={8} />
          </div>
          <div className="loading-text full-width centered">{loadingText || "Loading"}</div>
        </div>        
      }
      {
        !isLoading &&
        children
      }
    </Fragment>
  )
}

LoadingSection.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.node
}

export default LoadingSection;