
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import LoadingButton from 'Components/LoadingButton';
import { useApiWorker } from 'Utilities/CustomHooks';
import Render from "Components/Render";
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {TIME_SETTINGS} from 'Utilities/Constants';

function SendButton(props){
	const {displayName, userEvent, socialEventId, handleSuccess, handleError, 
			baseUrl, postUrl, value, valueName, username, className} = props;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isMessageSent, setMessageSent] = useState(false);

  	const [apiWorker] = useApiWorker(baseUrl);

	const SendMessage = async () => {
		setIsSubmitting(true);
	    var data = { ...userEvent, user: { userName: username }}
	    data[valueName] = value;
	    data.socialEvent.id = socialEventId || null;
      	data.socialEvent.dateCreated = moment().format(TIME_SETTINGS.externalFormat);

	    try{
	        var resp = await apiWorker.post(postUrl, data);
	        if (!resp.data.errorMessage) {
	            setMessageSent(true);
	            handleSuccess(resp.data.socialEventId);
	        }else{
	          handleError(resp.data.errorMessage);
	        }
	    }catch(e){
			handleError("Something was wrong!");
	    }
	    setIsSubmitting(false);
	};

	return(
		<LoadingButton loading={isSubmitting} onClick={SendMessage}
			className={"full-width btn-send " + (className ? className : "")} color={!isMessageSent ? "primary" : "lightgreen"} disabled={isMessageSent}>
			<Render condition={!isMessageSent}>
				{displayName}
			</Render>
			<Render condition={isMessageSent}>
				<FontAwesomeIcon icon={faCheck}/>
			</Render>
		</LoadingButton>
	);
}

export default SendButton;