import ActionTypes from './ActionTypes';
import { INITIAL_STATE } from 'Utilities/Constants';

const Reducer = (state, action) => {
  const { setEvent, setDrinkTypes, setDrinkStyles, setUserEvent, clearTypes, clearEvent, setDeliveryTypes, setStoreInformation, clearStoreInformation, clearEventPersistStoreInfo} = ActionTypes;

  switch (action.type) {
    case setEvent: {
      return {
        ...state,
        eventCreated: true,
        event: action.payload
      }
    }
    case setDrinkTypes: {
      return {
        ...state,
        drinkTypeSelections: action.payload
      }
    }
    case setDrinkStyles: {
      var stateWithStyles = {
        ...state,
        drinkStyleSelections: {
          ...state.drinkStyleSelections,
          [action.payload.drinkTypeId]: action.payload.styleList
        }
      }

      if(action.payload.isMixers) {
        stateWithStyles.mixerTypeId = action.payload.drinkTypeId;
      }
      return stateWithStyles;
    }
    case setUserEvent: {
      return {
        ...state,
        userEvent: action.payload
      }
    }
    case clearTypes: {
      const {event, storeInfo,...others} = state;
      return {
        eventCreated: false,
        event: event,
        storeInfo: storeInfo
      }
    }
    case clearEvent: {
      return {
        ...INITIAL_STATE
      }
    }
    case setDeliveryTypes:{
      return {
        ...state,
        deliveryTypes: action.payload
      }
    }
    case setStoreInformation:{
      return{
        ...state,
        storeInfo: action.payload
      }
    }
    case clearEventPersistStoreInfo: {
      const{storeInfo} = state;
      return {
        ...INITIAL_STATE,
        storeInfo: storeInfo
      }
    }
    default:
        return state;
  }
};

export default Reducer;