import './DrinkCalculator.scss';
import React, {useState, useEffect} from 'react';
import { ModalBody, Collapse } from 'reactstrap';
import LoadingButton from 'Components/LoadingButton';
import ActionTypes from 'Context/ActionTypes';
import { ENDPOINTS } from 'Utilities/Constants';
import {listToObj, mapObjectEntries, compareOrderables} from 'Utilities/HelperFunctions';
import {useApiWorker, useCalculatorContext} from 'Utilities/CustomHooks';
import RefreshRedirect from './RefreshRedirect';
import ResultsRow from './ResultsRow';
import LoadingSection from 'Components/LoadingSection';
import SendShoppingList from './SendShoppingList';
import ModalCloseButton from 'Components/ModalCloseButton';
import EssentialItemRow from './EssentialItemRow';
import BlogLinksSection from './BlogLinksSection';
import ReactGA from 'react-ga';
import ShoppingListSuccessModal from './ShoppingListSuccessModal';
import Render from 'Components/Render';
import QuoteForm from '../GetAQuote/QuoteForm';
import QuoteSuccessMessage from '../GetAQuote/QuoteSuccessMessage';
import ReminderLoginLabel from './ReminderLoginLabel';
import {ROUTES, TIME_SETTINGS} from 'Utilities/Constants';
import { getProfile, loggedIn } from 'Utilities/AuthService';
import { saveEvent } from 'Utilities/LocalSocialEventService';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

function ViewResults(props) {
  const {config, ...other} = props;

  const [state, sendUpdate] = useCalculatorContext();
  const [results, setResults] = useState(listToObj(state.results || []));
  const [blogLinks, setBlogLinks] = useState([]);
  const [otherEssentials, setOtherEssentials] = useState([]);
  const [apiWorker] = useApiWorker(config.baseUrl);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [successModalShowing, setSuccessModalShowing] = useState(false);

  const [isQuoteModalShowing, setIsQuoteModalShowing] = useState(false);
  const [successQuoteModalShowing, setSuccessQuoteModalShowing] = useState(false);

  const [user, setUser] = useState({username:""});
  const [socialEventId, setSocialEventId ] = useState(null);
  const [eventCalculated, setEventCalculated] = useState(false);
  let history = useHistory();

  const getCalculationsModel = () => {
    var drinkTypes = state.drinkTypeSelections.filter(type => type.isSelected);
    var typesWithStyles = drinkTypes.map(type => {
      if(state.drinkStyleSelections && state.drinkStyleSelections[type.id]) {
        var allStyles = (state.drinkStyleSelections[type.id] || []);
        var hasStyles = allStyles.length > 0;
        var selectedStyles = [];
        if(hasStyles) {
          selectedStyles = allStyles.filter(s => s.isSelected);
          if(selectedStyles.length === 0) {
            selectedStyles = allStyles.filter(s => s.isDefault);
          }
        }
        return {...type, styles: selectedStyles};
      }
      return type;
    });

    return {
      socialEvent: {...state.event},
      drinktypes: typesWithStyles
    }
  }

  const toggleModal = () => {
    if(isModalShowing) {
      ReactGA.modalview('send-shopping-list-modal');
    }
    setIsModalShowing(!isModalShowing);
    window.scrollTo(0, 0);
  }

  const toggleSuccessModal = () => {
    setSuccessModalShowing(!successModalShowing);
  }

  const toggleQuoteModal = () => {
    if(!isQuoteModalShowing) {
      ReactGA.modalview('get-a-quote-modal');
    }
    setIsQuoteModalShowing(!isQuoteModalShowing);
    window.scrollTo(0, 0);
  }

  const toggleSuccessQuoteModal = () => {
    setSuccessQuoteModalShowing(!successQuoteModalShowing);
  }

  const isLastEntry = (index, obj) => {
    if(Array.isArray(obj)) {
      return index === obj.length - 1;
    }
    return index === Object.entries(obj).length - 1;
  }

  const calculateResults = async () => {
    var model = getCalculationsModel();
    const res = await apiWorker.post(ENDPOINTS.drinkCalculator.main, model);
    const data = res.data || {};
    model.recommendations = data.recommendations || [];
    model.essentialItems = data.essentialItems || [];
    await sendUpdate(ActionTypes.setUserEvent, model);
    const results = listToObj(data.recommendations, "name");
    setBlogLinks(data.blogLinks || []);
    setOtherEssentials(data.essentialItems || []);
    setResults(results);
    setIsPageLoading(false);
    setEventCalculated(true);
  }

  useEffect(() => {
    const getResults = async () => {
      await calculateResults();
     
    }

    setIsPageLoading(true);
    if(state.eventCreated) {
      getResults();
    } else {
      setIsPageLoading(false);
    }
  }, []);

  useEffect(()=>{
    saveShoppingList();
    
  }, [state]);

  useEffect(()=>{
    if (state.storeInfo.name.length > 0 && eventCalculated){
      console.log(results);
      history.push(ROUTES.storelocator);
    }    
  }, [eventCalculated]);

  const saveShoppingList = async ()=>{
    if (state.userEvent && !state.userEvent.id) {
      var data = { ...state.userEvent, user: { userName: user.username, noSendInformation: true }}
      data.socialEvent.dateCreated = moment().format(TIME_SETTINGS.externalFormat);
      var resp = await apiWorker.post(ENDPOINTS.socialEvents.save, data);
      if (resp.data.errorMessage) {
          console.error(`ERROR SAVING SOCIAL EVENT: ${resp.data.errorMessage}`);
          return;
      }
      state.userEvent.socialEvent.id = resp.data;
      sendUpdate(state);
      setSocialEventId(resp.data);
      saveEvent(resp.data);
    }
  };

  return (
    <RefreshRedirect>
      <Render condition={(!isModalShowing && !successModalShowing && !isQuoteModalShowing && !successQuoteModalShowing)}>
        <div className="view-results card-content">
          <LoadingSection isLoading={isPageLoading} loadingText="Calculating Results">
            <h1 className="card-heading results-heading">order up!
              <div className="full-width centered sub-heading">
                For {state.event.numberOfGuests} guests, you need:
              </div>
            </h1>
            {
              mapObjectEntries(results, compareOrderables, ([, recommendation], index) => 
                <ResultsRow recommendation={recommendation} isLastEntry={isLastEntry(index, results)} key={index}/>
              )
            }
            <div className="centered action-buttons">
              <LoadingButton loading={false} disabled={false} onClick={toggleQuoteModal}
                className="btn-primary" color="primary">
                  Get A Quote
              </LoadingButton>
              <LoadingButton loading={false} disabled={false} onClick={toggleModal}
                className="btn-primary" color="primary">
                  Send My Shopping List
              </LoadingButton>  
            </div>
            <ReminderLoginLabel {...props} redirectTo={ROUTES.results}/>
            <div className="results-section">
              <h2>other essentials</h2>
              {
                otherEssentials.sort(compareOrderables).map((essentialItem, index) => 
                  <EssentialItemRow essentialItem={essentialItem} key={index} isLastEntry={isLastEntry(index, otherEssentials)} />
                )
              }
            </div>
            <div className="centered action-buttons">
              <LoadingButton loading={false} disabled={false} onClick={toggleQuoteModal}
                className="btn-primary" color="primary">
                  Get A Quote
              </LoadingButton>
              <LoadingButton loading={false} disabled={false} onClick={toggleModal}
                className="btn-primary" color="primary">
                  Send My Shopping List
              </LoadingButton>
            </div>
            <ReminderLoginLabel {...props} redirectTo={ROUTES.results}/>
            <BlogLinksSection title="get thirsty" links={blogLinks} />
          </LoadingSection>
        </div>
      </Render>
      <Render condition={isModalShowing}>
        <ModalBody>
          <ModalCloseButton toggle={toggleModal} />
          <SendShoppingList config={config} setIsModalShowing={setIsModalShowing} setSuccessModalShowing={setSuccessModalShowing}/>
        </ModalBody>      
      </Render>
      <Render condition={successModalShowing}>
        <ShoppingListSuccessModal toggle={toggleSuccessModal}/>
      </Render>
      <Render condition={isQuoteModalShowing}>
        <ModalBody>
          <ModalCloseButton toggle={toggleQuoteModal} />
          <QuoteForm config={config} setIsModalShowing={setIsQuoteModalShowing} setSuccessQuoteModalShowing={setSuccessQuoteModalShowing} recommendations={results} essentials={otherEssentials} />
        </ModalBody>
      </Render>
      <Render condition={successQuoteModalShowing}>
        <div className="quote-success-message">
          <ModalCloseButton toggle={()=>{setSuccessQuoteModalShowing(false)}} /> 
          <QuoteSuccessMessage toggle={toggleSuccessQuoteModal}/>  
        </div>    
      </Render>
    </RefreshRedirect>
  )
};

export default ViewResults;