import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons';
import { useApiWorker } from 'Utilities/CustomHooks';
import { ENDPOINTS } from 'Utilities/Constants';
import { getProfile } from 'Utilities/AuthService';
import Render from "Components/Render";
import { Row, Col } from 'reactstrap';
import LoadingSection from 'Components/LoadingSection';
import {persistSocialEvent} from 'Utilities/LocalSocialEventService';

const minEventsRequired = 1;

function SocialEventList(props){

	const { config } = props;
	const [apiWorker] = useApiWorker(config.baseUrl);
	const [events, setEvents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(()=>{
		const getData = async () => {
			const user = getProfile();
			await persistSocialEvent(apiWorker);
			var resp = await apiWorker.get(`${ENDPOINTS.socialEvents.byUser}/${user.nameid}`);
			setEvents(resp.data);
			setIsLoading(false);
		}
		getData();
	}, []);

	return(
		<LoadingSection isLoading={isLoading}>
			<ThumbnailGroup events={events} {...props}></ThumbnailGroup>
		</LoadingSection>
	);
}

export default SocialEventList;

const ThumbnailGroup = (props) => {
	const { events } = props;
	var missingEvents = minEventsRequired - events.length;

	for (var i = 0; i < missingEvents; i++) {
		events.push({isEmpty:true});
	}

	return(
		<Row className="thumbnail-group">
			<Col xl={4} lg={6} md={6} sm={6} xs={12}>
				<CreateNew {...props}/>
			</Col>	
			{events.map((event) => {
				return(
					<Col xl={4} lg={6} md={6} sm={6} xs={12}>
						<Render condition={!event.isEmpty}>
							<Thumbnail id={event.id} eventDate={event.dateCreated} eventName={event.name} {...props}/>
						</Render>
					</Col>
				);
			})}			
		</Row>
	);
}

const Thumbnail = (props) => {
	const { id, eventDate, eventName, clickEvent } = props;
	var momentDate = Moment(eventDate, 'YYYY-MM-DD');

	return(
		<div className="thumbnail" onClick={clickEvent} id={id}>
			<Render condition={eventName}>
				<div className="caption-name">	
					{eventName}			
				</div>
			</Render>
			<Render condition={!eventName}>
				<div className="caption-no-name">				
					<div>{momentDate.format('MM')}</div>
					<div>{momentDate.format('DD')}</div>
					<div>{momentDate.format('YY')}</div>
				</div>
			</Render>
			<div className="hidden-caption" onClick={clickEvent} id={id}>
				{eventName || 'NO EVENT NAME'}
			</div>
		</div>
	);
}

const CreateNew = (props) => {
	const { createNew } = props
	return(
		<div className="thumbnail-new" onClick={createNew}>
			<div className="caption">
				<div className="plus-char">+</div>
				<div>Create New</div>
			</div>
		</div>
	);
}
