import "./ArrowButton.scss";
import React from 'react';
import {Arrow} from './CustomIcons';

function ArrowButton ({displayText, handleClick, ...others}){
    return (
        <div className="arrow-button-wrapper" onClick={() => {handleClick !== undefined && handleClick()}}>
            <div className="arrow-button-content">{displayText}</div>
            <Arrow className="arrow-button" width={25} height={25} />
        </div>
    );
}

export default ArrowButton;