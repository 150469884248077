import './Login.scss';
import React, {useState} from 'react';
import LoginForm from './LoginForm';
import { Row } from 'reactstrap';
import ReactGA from 'react-ga';
import Render from 'Components/Render';
import SignUp from './SignUp';
import ChangePassword from './ChangePassword';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import ModalCloseButton from 'Components/ModalCloseButton';

function Login(props) {
    const {config, toggle, onBeforeLogin, onHandleSuccess, onHandleError, title, subtitle, noRedirect, ...others} = props;
    const [isSignUpShowing, setSignUpShowing] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showSucccessPasswordChange, setShowSuccessPasswordChange] = useState(false);
    const [showErrorPasswordChange, setShowErrorPasswordChange] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    let hasSubtitle = subtitle || false;

    const toggleModal = () => {
        if(!isSignUpShowing) {
            ReactGA.modalview('sign-up-modal');
          }
          setSignUpShowing(!isSignUpShowing);
          window.scrollTo(0, 0);
    }

    const forgotPasswordHandler = () => setShowChangePassword(!showChangePassword);
    const handleSuccessChangePassword = () => setShowSuccessPasswordChange(!showSucccessPasswordChange);
    const handleErrorChangePassword = (resp) => {
        setPasswordErrorMessage(resp);
        setShowErrorPasswordChange(!showErrorPasswordChange);
    }

    return (
        <div>
        <Render condition={!isSignUpShowing}>
            <div className='login-container'>
            <div className="login-greeting centered">{title || "Hey, Party Animal!"}</div>
            <Render condition={hasSubtitle}>
                <div className="login-sub-greeting centered">{subtitle}</div>
            </Render>
            <div className="login-wrapper">
                <div className="login-main">
                    <LoginForm config={config} {...others} collapse={toggle} noRedirect={noRedirect ?? true}  onBeforeLogin={ onBeforeLogin} onHandleSuccess={onHandleSuccess} onHandleError={onHandleError}/>
                    <div className="forgot-password centered">
                        <a onClick={forgotPasswordHandler}>Forgot your password?</a>
                    </div>
                </div>
            </div>

            <div id="no-account" className="centered">
                <a onClick={toggleModal}>New here? sign up!</a>
            </div>
            
            <div className="gray-box-wrapper">
                <div className="full-width centered">With an account, you can:</div>
                <div className="drink-calculator-specs">
                    <ul className="plus">
                        <li>access past shopping lists</li>
                        <li>rate the goods you tried</li>
                        <li>make notes like a pro</li>
                        <li>get quotes straight from our store locator</li>
                    </ul>
                </div>
            </div>
            </div>
        </Render>
        
        <Render condition={isSignUpShowing}>
            <SignUp config={config} setIsModalShowing={setSignUpShowing} {...others} collapse={toggle} noRedirect={true}  onBeforeLogin={ onBeforeLogin} onHandleSuccess={onHandleSuccess}/>
            <div id="no-account" className="centered">
                Already a party animal? <a onClick={toggleModal}>login!</a>
            </div>
        </Render>
        <ChangePassword isForgettingPassword={true} emailAddress={""} {...props} handleSuccess={handleSuccessChangePassword} handleError={handleErrorChangePassword} modalShow={showChangePassword} modalToggle={forgotPasswordHandler} />
        <Modal isOpen={showErrorPasswordChange} toggle={handleErrorChangePassword} backdrop={true} backdropClassName="primary-backdrop">
            <ModalCloseButton toggle={handleErrorChangePassword} />
            <ModalHeader>
                Error
            </ModalHeader>
            <ModalBody>
                {passwordErrorMessage}
            </ModalBody>
        </Modal>

        <Modal isOpen={showSucccessPasswordChange} toggle={handleSuccessChangePassword} backdrop={true} backdropClassName="primary-backdrop">
            <ModalCloseButton toggle={handleSuccessChangePassword} />
            <ModalHeader>
                Thank you!
            </ModalHeader>
            <ModalBody>
                An email has been sent with a link to reset your password.
            </ModalBody>
        </Modal>
        </div>
    )
}

export default Login;
