import "./Incrementor.scss";
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Plus, Minus } from "./CustomIcons";
import {Input} from 'reactstrap';
import NumberFormat from 'react-number-format';

function Incrementor({value, setValue, label, maxVal, setError, maxValueError="Error", allowEditing=true, hasError, ...other}) { 
  const [editing, setEditing] = useState(false);
  const [reachedMax, setReachedMax] = useState(value === maxVal);

  const handleChange = (newVal) => {
    if(newVal === maxVal) {
      setReachedMax(true);
    } else {
      setReachedMax(false);
      setError && setError("");
    }
    
    setValue(newVal);
  }

  const decrement = () => {
    var newVal = value > 0 ? value - 1 : 0;
    handleChange(newVal);
  }

  const increment = () => {
    var newVal = maxVal !== undefined 
      ? (value < maxVal ? value + 1 : maxVal ) 
      : value + 1;
    handleChange(newVal);
  }

  const beginEdit = () => {
    if(allowEditing) {
      setEditing(true);
    }
  }

  const onEditValue = (event) => {
    var val = parseInt(event.value);
    if(val < 0 || isNaN(val)) val = 0;
    if(maxVal && val > maxVal) {
      val = maxVal;
      setError && setError(maxValueError);
    }

    handleChange(val);
  }

  return (
    <div className="incrementor-wrapper">
      <div className="incrementor">
        <div className="decrement-btn" onClick={decrement}><Minus height="18px"/></div>
        <div className={"value-display " + (allowEditing ? "editable " : "")}>
          {!editing ? 
            <div className={"current-value"} onClick={beginEdit}>{value}</div>
            : <NumberFormat customInput={Input} onValueChange={onEditValue} 
                onBlur={() => setEditing(false)} className="editing-value" defaultValue={value} 
                onClick={(event) => event.target.select()} onFocus={(event) => event.target.select()}
                onKeyDown={(e) => {if(e.key === 'Enter') setEditing(false);}} allowNegative={false} decimalScale={0} autoFocus inputMode="numeric"/>
          }
        </div>
        <div className={"increment-btn " + (reachedMax && "max-value")} onClick={increment}><Plus height="18px"/></div>
      </div>
      <div className="input-label">{label}</div>
    </div>
  );
};

Incrementor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  maxVal: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string)
}

export default Incrementor;