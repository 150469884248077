import "./CheckableBox.scss";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';

function CheckableBox(props) { 
  const {handleClick, outlined, selected, unclickable, wide, short, ...others} = props;

  return (
    <div className={"checkable-box-wrapper centered " 
      + (outlined ? "outlined " : "solid ") 
      + (selected ? "active " : "")
      + (unclickable ? "unclickable " : "")
      + (wide ? "wide-box " : "")
      + (short ? "short-box " : "")
    }
      onClick={() => {handleClick !== undefined && handleClick()}}
      {...others}
    >
      <div className={"circle-check "}>
        <CheckIcon className="check" fontSize="large"/>
      </div>
      <div className="box-content">
        {props.children}
      </div>
    </div>
  );
};

CheckableBox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  outlined: PropTypes.bool,
  selected: PropTypes.bool,
  unclickable: PropTypes.bool,
  wide: PropTypes.bool
}

export default CheckableBox;