import React, {useState, useEffect} from 'react';
import 'Features/Home/NavMenu.scss';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink , Modal, ModalBody} from 'reactstrap';
import NewTabLink from 'Components/NewTabLink';
import { URLS } from 'Utilities/Constants';
import { useCalculatorContext } from 'Utilities/CustomHooks';
import ActionTypes from 'Context/ActionTypes';
import { useHistory, NavLink as RouterNavLink } from 'react-router-dom';
import { ROUTES } from 'Utilities/Constants';
import { loggedIn } from 'Utilities/AuthService';
import Render from 'Components/Render';
import Login from 'Features/User/Login';
import LoginButton from 'Components/LoginButton';
import LoadingButton from 'Components/LoadingButton';
import ModalCloseButton from 'Components/ModalCloseButton';
import AccountIcon from 'Components/AccountIcon';
import DiscourseNavItem from 'Features/Discourse/DiscourseNavItem';

export default function NavMenu(props) {
  const {config, ...other} = props;
  const [shrunk, setShrunk] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [state, sendUpdate] = useCalculatorContext();
  const [collapsedLogin, setCollapsedLogin] = useState(true);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  let history = useHistory();

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
    setCollapsedLogin(true)
  };
  const toggleLogin = () => {
    setCollapsed(true)
    setCollapsedLogin(!collapsedLogin);
  }

  const toggleLoginModal = () => setLoginModalOpen(!loginModalOpen);
    
  const links = [
    {name: "Events", url: URLS.CRAVENCO_SITE_MAIN},
    {name: "Get Thirsty", url: URLS.BLOG_MAIN},
  ]

  const clearStateAndRedirectToLiquorCabinet = async() => {
    setCollapsed(true);
    await sendUpdate(ActionTypes.clearEvent, null);
    history.push(ROUTES.liquorCabinet);
  }

  const clearStateAndRedirect = async() => {
    setCollapsed(true);
    await sendUpdate(ActionTypes.clearEvent, null);
    history.push(ROUTES.eventSetup);
  }

  const clearStateAndRedirectToStoreLocator = async() => {
    setCollapsed(true);
    await sendUpdate(ActionTypes.clearEvent, null);
    history.push(ROUTES.storelocator);
  }

  const clearStateAndRedirectToMyAccount = async() => {
    setCollapsed(true);
    await sendUpdate(ActionTypes.clearEvent, null);
    history.push(ROUTES.myAccount);
  } 

  const checkActive = (match, location) => {
    if(!location) return false;
    const {pathname} = location;
    return pathname === "/" || pathname === ROUTES.eventSetup;
  }

  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop;
      const shrinkOn = 100;

      if (distanceY > shrinkOn) {
        setShrunk(true);
        setCollapsed(true);
        setCollapsedLogin(true);
      } else {
        setShrunk(false);
      }
    }
    window.addEventListener('scroll', resizeHeaderOnScroll);
  }, [])

  return (
    <header>
      <div className={"main-header " + (shrunk ? "smaller" : "")}>
        <a href="/">
          <span  className="logo"></span>
        </a>
        <div className="right-nav">
          <RouterNavLink className="nav-item" to="/" isActive={checkActive} onClick={clearStateAndRedirect}>
            The Drink Calculator
          </RouterNavLink>      
          <RouterNavLink className="nav-item" to={ROUTES.storelocator} onClick={clearStateAndRedirectToStoreLocator} >
            Store Locator
          </RouterNavLink>
          <RouterNavLink className="nav-item" to={ROUTES.liquorCabinet} onClick={clearStateAndRedirectToLiquorCabinet}>
            Liquor Cabinet
          </RouterNavLink> 
          {
            links.map((item, index) => 
              <NewTabLink key={index} className="nav-item" url={item.url}>{item.name}</NewTabLink>
            )
          }
          <DiscourseNavItem {...props}/>
          <Render condition={loggedIn()}>
            <AccountIcon className="nav-item" handleClick={clearStateAndRedirectToMyAccount}>
            </AccountIcon>
          </Render>
          <Render condition={!loggedIn()}>
            <LoadingButton className="login-btn btn-primary" color="primary" onClick={toggleLoginModal}>Log in</LoadingButton>
        </Render>
        </div>
        <div className="mobile-nav">
          <Render condition={loggedIn()}>
            <AccountIcon className="nav-item" handleClick={clearStateAndRedirectToMyAccount} />
          </Render>
          <Render condition={!loggedIn()}>
            <Navbar className="mobile-login" color="faded" light>
              <LoadingButton className="login-btn btn-primary" color="primary" onClick={!loggedIn() ? toggleLogin : clearStateAndRedirectToMyAccount}>Log in</LoadingButton>
            </Navbar>
          </Render>
          <Navbar color="faded" light>
            <NavbarToggler className="navbar-toggler" onClick={toggleNavbar} className="mr-2" />
          </Navbar>
        </div>
      </div>
      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar expanded={collapsed.toString()}>
        <NavItem >
            <RouterNavLink className="mobile-nav-link mobile-route-link" to={ROUTES.eventSetup} onClick={clearStateAndRedirect}>The Drink Calculator</RouterNavLink>
        </NavItem>
        <NavItem >
            <RouterNavLink className="mobile-nav-link mobile-route-link" to={ROUTES.storelocator} onClick={clearStateAndRedirectToStoreLocator}>Store Locator</RouterNavLink>
        </NavItem>
        <NavItem >
            <RouterNavLink className="mobile-nav-link mobile-route-link" to={ROUTES.liquorCabinet} onClick={clearStateAndRedirectToLiquorCabinet}>Liquor Cabinet</RouterNavLink>
        </NavItem>
        <DiscourseNavItem {...props}/>
        <Render condition={loggedIn()}>
          <NavItem >
              <RouterNavLink className="mobile-nav-link mobile-route-link" to={ROUTES.myAccount}  onClick={clearStateAndRedirectToMyAccount}>My Account</RouterNavLink>
          </NavItem>
        </Render>
          {
            links.map((item, index) => 
              <NavItem key={index}>
                <NavLink href="/"><NewTabLink className="mobile-nav-link" url={item.url}>{item.name}</NewTabLink></NavLink>
              </NavItem>
            )
          }
        </Nav>
      </Collapse>

      <Collapse isOpen={!collapsedLogin} navbar className="login-collapse">
        <Nav navbar>
        <Login config={config} toggle={toggleLogin}/>
        </Nav>
      </Collapse>
      <Modal isOpen={loginModalOpen} toggle={toggleLoginModal} className="login-modal" backdrop={true} backdropClassName="primary-backdrop">
        <ModalBody>
          <ModalCloseButton toggle={toggleLoginModal} />
          <Login config={config} toggle={toggleLoginModal}></Login>
        </ModalBody>
      </Modal>
    </header>
  );
}
