import './ReminderLoginLabel.scss';
import React, {useState} from 'react';
import Login from '../User/Login';
import SignUp from '../User/SignUp';
import LoadingButton from 'Components/LoadingButton';
import ModalCloseButton from 'Components/ModalCloseButton';
import Render from 'Components/Render';
import {loggedIn} from 'Utilities/AuthService';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink , Modal, ModalBody} from 'reactstrap';

function ReminderLoginLabel(props){
	const {config, redirectTo} = props;

	//login
	const [collapsedLogin, setCollapsedLogin] = useState(true);
	const [loginModalOpen, setLoginModalOpen] = useState(false);

	const toggleLoginModal = () => setLoginModalOpen(!loginModalOpen);

	//signup
	const [collapsedSignup, setCollapsedSignup] = useState(true);
	const [signupModalOpen, setSignupModalOpen] = useState(false);

  	const toggleSignupModal = () => setSignupModalOpen(!signupModalOpen);

	return(
		<Render condition={!loggedIn()}>
			<div className="reminder-login-label">
				To save your order, make sure you're <a className="login" onClick={toggleLoginModal} href="#">logged in</a>, or <a className="signup" onClick={toggleSignupModal} href="#">create an account</a>
			</div>
			<Modal isOpen={loginModalOpen} toggle={toggleLoginModal} className="login-modal" backdrop={true} backdropClassName="primary-backdrop">
				<ModalBody>
					<ModalCloseButton toggle={toggleLoginModal} />
					<Login config={config} toggle={toggleLoginModal} redirectTo="/Results#"></Login>
				</ModalBody>
			</Modal>
			<Modal isOpen={signupModalOpen} toggle={toggleSignupModal} className="signup-modal" backdrop={true} backdropClassName="primary-backdrop">
				<ModalBody>
					<ModalCloseButton toggle={toggleSignupModal} />
					<SignUp config={config} collapse={toggleSignupModal} redirectTo="/Results#"></SignUp>
				</ModalBody>
			</Modal>
		</Render>
	);
}

export default ReminderLoginLabel;